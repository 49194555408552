import * as d3 from "d3";

export interface HBarChartProps {
  el: any;
  barHeight: number;
  data: HBarChartDataPoint[];
  labelWidth: number;
}

export interface HBarChartDataPoint {
  id: string;
  label: string;
  value: number;
  className?: string;
  valueLabel?: string;
  onClick?: (point: HBarChartDataPoint) => any;
}

export class HorizontalBarChart {
  constructor(props: HBarChartProps) {
    this.update(props);
  }

  public update(props: HBarChartProps) {
    const boundingRect = props.el.getBoundingClientRect();

    const x = d3
      .scaleLinear()
      .domain([0, d3.max(props.data.map((d) => d.value)) || 0])
      .range([0, boundingRect.width - 40]);

    d3.select(props.el).select("svg").remove();

    const chart = d3
      .select(props.el)
      .append("svg")
      .attr("class", "basic-chart")
      .classed("svg-content-responsive", true)
      .attr("height", props.data.length * props.barHeight)
      .attr("width", boundingRect.width);

    const bar = chart
      .selectAll("*")
      .remove()
      .data(props.data)
      .enter()
      .append("g")
      .attr("class", (d, i) => {
        let classNames = d.className || "";
        if (d.onClick) {
          classNames += " has-action";
        }

        const barWidth = x(d.value);

        if (barWidth === 0) {
          classNames += " is-zero-width";
        }

        return classNames;
      })
      .attr("transform", (d, i) => {
        return "translate(0," + i * props.barHeight + ")";
      })
      .on("click", (d) => {
        if (d.onClick) {
          d.onClick(d);
        }
        (d3 as any).event.stopPropagation();
      });

    bar
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => {
        return 0;
      })
      .attr("width", (d) => {
        const barWidth = x(d.value);
        return barWidth;
      })
      .attr("height", props.barHeight - 1);

    bar
      .append("text")
      .attr("class", "label")
      .attr("x", 4)
      .attr("y", props.barHeight / 2)
      .attr("dy", ".35em")
      .text((d) => {
        return d.label;
      })
      .each((dataPoint, a, el) => {
        this.wrap(el, props.labelWidth, 5);
      });

    bar
      .append("text")
      .attr("class", "value")
      .attr("x", (d) => {
        return x(d.value) + 3;
      })
      .attr("y", props.barHeight / 2)
      .attr("dy", ".35em")
      .text((d) => {
        return d.valueLabel ? " " + d.valueLabel : d.value;
      });
  }

  private wrap(el: any, width: number, padding: number) {
    return () => {
      const self = d3.select(el);
      let textLength = self.node().getComputedTextLength();
      let text = self.text();
      while (textLength > width - 2 * padding && text.length > 0) {
        text = text.slice(0, -1);
        self.text(text + "...");
        textLength = self.node().getComputedTextLength();
      }
    };
  }

  public destroy() {
    // Any clean-up would go here
    // in this example there is nothing to do
  }
}
