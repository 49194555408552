import { Grid, Fade } from "@material-ui/core";
import { PlaceholderBox, PlaceholderLine } from "../../misc";
import { Page, PageContent, ConstrainedPageContent } from "../../layout";

const EstimateHeaderPlaceholder = () => {
  return (
    <Fade in>
      <div className="estimate-placeholder">
        <Page>
          <PageContent>
            <div className="estimate-header">
              <ConstrainedPageContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <PlaceholderLine width="220px" thickness="thin" className="banner-placeholder-line" />
                      </Grid>
                      <Grid item xs={12}>
                        <PlaceholderLine width="320px" thickness="thick" className="banner-placeholder-line" />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="banner-placeholder-boxes">
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={3}>
                        <PlaceholderBox size="full" color="dark" className="banner-placeholder-box" />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <PlaceholderBox size="full" color="dark" className="banner-placeholder-box" />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <PlaceholderBox size="full" color="dark" className="banner-placeholder-box" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ConstrainedPageContent>
            </div>
          </PageContent>
        </Page>
      </div>
    </Fade>
  );
};

export default EstimateHeaderPlaceholder;
