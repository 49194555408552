import * as React from "react";
import { Container } from "@material-ui/core";

const ConstrainedPageContent: React.FunctionComponent<{ className?: string }> = (props) => {
  return (
    <Container maxWidth="lg">
      <>{props.children}</>
    </Container>
  );
};

export default ConstrainedPageContent;
