import * as React from "react";
import { EstimateItem } from "../../api/models";

interface ConfidenceIndicatorProps {
  item: EstimateItem;
}

export class ConfidenceIndicator extends React.PureComponent<ConfidenceIndicatorProps> {
  public render() {
    const valueSet = this.props.item.rangeLow != null && this.props.item.rangeHigh != null;

    const confidenceClassName = this.getConfidenceClassName();

    return valueSet ? (
      <span className={"confidence-dot " + confidenceClassName} title={"Confidence: " + confidenceClassName}>
        <span className="dot" />
      </span>
    ) : null;
  }

  private getConfidenceClassName() {
    const confidenceLevel = this.calculateConfidence(this.props.item.rangeLow, this.props.item.rangeHigh);

    if (confidenceLevel === 0) {
      return "none";
    } else if (confidenceLevel === 0.25) {
      return "low";
    } else if (confidenceLevel === 0.5) {
      return "medium";
    } else if (confidenceLevel === 0.75) {
      return "high";
    } else if (confidenceLevel === 1) {
      return "extremely-high";
    }
    return "";
  }

  private calculateConfidence(low: number | null, high: number | null): number {
    if (low !== null && high !== null) {
      const div = high / low;
      const sub = high - low;

      if (div <= 1 || sub <= 2) {
        return 1;
      } else if (div <= 1.3 || sub <= 4) {
        return 0.75;
      } else if (sub <= 1.5 || sub <= 8) {
        return 0.5;
      } else {
        return 0.25;
      }
    }
    return 0;
  }
}
