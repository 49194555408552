import * as React from "react";
import { IconButton, Button, List } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { ContentContainer, Form, FormSection, FormField, PageActionBar } from "../../layout";
import { AreaEditor } from "./AreaEditor";
import { SettableEstimateAreaProps, SettableEstimateItemProps } from "../models";
import { CsvFileLoader } from "./CsvFileLoader";
import EffortFormSettings from "./EffortFormSettings";
import { EffortFormProps, EffortFormState } from "./EffortForm";

export class EffortForm extends React.Component<EffortFormProps, EffortFormState> {
  public state: EffortFormState = { showEffortSettings: false };

  public shouldComponentUpdate(nextProps: EffortFormProps, nextState: EffortFormState) {
    return (
      nextProps.roles !== this.props.roles ||
      nextProps.teamMembers !== this.props.teamMembers ||
      nextProps.areas !== this.props.areas ||
      nextProps.areaTotals !== this.props.areaTotals ||
      nextProps.itemModifiers !== this.props.itemModifiers ||
      nextState.showEffortSettings !== this.state.showEffortSettings
    );
  }

  public render() {
    const { estimate, teamMembers, itemModifiers, roles } = this.props;
    const { showEffortSettings } = this.state;

    return (
      <ContentContainer elevation={0}>
        <Form fullWidth>
          <FormSection
            title={
              <span>
                Effort breakdown{" "}
                <IconButton aria-label="Settings" className="area-delete" onClick={this.toggleEffortSettings}>
                  <SettingsIcon />
                </IconButton>
              </span>
            }
            fullWidth>
            {showEffortSettings ? (
              <EffortFormSettings
                roles={estimate.roles}
                itemModifiers={estimate.itemModifiers}
                addItemModifier={this.props.addItemModifier}
                deleteItemModifier={this.props.deleteItemModifier}
                setItemModifierProps={this.props.setItemModifierProps}
                setRoleProps={this.props.setRoleProps}
              />
            ) : null}
            <FormField extraPadding>
              <List>
                {this.props.areas.map((area) => {
                  const areaTotal = this.props.areaTotals.find((t) => t.areaId === area.id);

                  return (
                    <AreaEditor
                      key={area.id}
                      area={area}
                      areaTotal={areaTotal!}
                      teamMembers={teamMembers}
                      deleteArea={this.deleteArea(area.id)}
                      roles={roles}
                      itemModifiers={itemModifiers}
                      setProps={this.setProps(area.id)}
                      addItem={this.addItem(area.id)}
                      setItemProps={this.setItemProps(area.id)}
                      deleteItem={this.deleteItem(area.id)}
                      moveUp={this.moveAreaUp(area.id)}
                      startItemMove={this.startItemMove(area.id)}
                      stopItemMove={this.stopItemMove}
                      completeItemMove={this.completeItemMove(area.id)}
                    />
                  );
                })}
              </List>
              <PageActionBar>
                <Button onClick={this.props.addArea} variant="contained" color="primary">
                  Add area
                </Button>
              </PageActionBar>
            </FormField>
            <FormField>
              <PageActionBar className="file-import">
                <header>Import from a CSV file</header>
                <p>
                  Expected file format: <br />
                  Area name, Task name, Task description, Low, High
                </p>
                <CsvFileLoader onFileImported={this.props.bulkImport} />
              </PageActionBar>
            </FormField>
          </FormSection>
        </Form>
      </ContentContainer>
    );
  }

  private toggleEffortSettings = () => {
    this.setState((s) => ({ ...s, showEffortSettings: !s.showEffortSettings }));
  };

  private setProps = (areaId: string) => (props: SettableEstimateAreaProps) => {
    this.props.setAreaProps(areaId, props);
  };

  private addItem = (areaId: string) => () => {
    this.props.addItem(areaId);
  };

  private deleteArea = (areaId: string) => () => {
    this.props.deleteArea(areaId);
  };

  private setItemProps = (areaId: string) => (itemId: string, props: SettableEstimateItemProps) => {
    this.props.setItemProps(areaId, itemId, props);
  };

  private deleteItem = (areaId: string) => (itemId: string) => {
    this.props.deleteItem(areaId, itemId);
  };

  private moveAreaUp = (areaId: string) => () => {
    this.props.moveAreaUp(areaId);
  };

  private completeItemMove = (areaId: string) => (itemId: string) => {
    this.props.completeItemMove(areaId, itemId);
  };

  private startItemMove = (areaId: string) => (itemId: string) => {
    this.props.startItemMove(areaId, itemId);
  };

  private stopItemMove = () => {
    this.props.stopItemMove();
  };
}
