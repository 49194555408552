import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: "-40px",
    marginRight: "-40px",

    "table.full-width": {
      "tr th:first-child, tr td:first-child": {
        paddingLeft: "40px"
      },
      "tr th:last-child, tr td:last-child": {
        paddingRight: "40px"
      }
    }
  }
}));

const StretchedContent: React.FunctionComponent = (props) => {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
};

export default StretchedContent;
