import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Paper, Table, TableCell, TableHead, TableRow, TableBody, Typography, makeStyles } from "@material-ui/core";
import { formatter } from "../../../utils/formatter";
import RiskScoreChip from "./RiskScoreChip";
import { EstimateSummary } from "../../../api/GraphQL/models";
import "@fontsource/montserrat/500.css";

const useStyles = makeStyles((theme) => ({
  client: {
    lineHeight: 1.1
  },
  name: {
    fontWeight: 500,
    color: theme.palette.type === "dark" ? theme.palette.text.lteal : theme.palette.primary.dark
  }
}));

const FilteredTable: React.FunctionComponent<{ estimates: EstimateSummary[] }> = (props) => {
  const classes = useStyles();
  const { estimates } = props;

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Risk</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Created</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {estimates.map((n) => {
            return (
              <TableRow key={n.id}>
                <TableCell>
                  <Typography variant="subtitle1" color="textPrimary" className={classes.client}>
                    {n.client?.name || "[Unknown client]"}
                  </Typography>
                  <Typography
                    variant="h5"
                    className={classes.name}
                    component={RouterLink}
                    to={`/estimates/${n.id}`}
                    style={{ textDecoration: "none" }}>
                    {n.name || "[no name]"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <RiskScoreChip riskAssessmentScore={n.riskAssessment.score} />
                </TableCell>
                <TableCell>
                  <Typography>${formatter.formatCurrency(n.plan.finalPrice)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{n.author?.fullName}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{new Date(n.dateCreated).toLocaleDateString()}</Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default FilteredTable;
