import * as React from "react";

export type NotificationSeverity = "success" | "info" | "warning" | "error" | undefined;

interface NotificationContextValue {
  message?: string;
  severity?: "success" | "info" | "warning" | "error";
  setNotification: (value: string | undefined, severity: NotificationSeverity) => any;
  clearNotification: () => any;
}

export const NotificationContext = React.createContext<NotificationContextValue>({
  setNotification: () => {},
  clearNotification: () => {}
});

export const useNotifications = () => React.useContext(NotificationContext);

const NotificationsProvider = (props: { children: React.ReactNode }) => {
  const [message, setMessage] = React.useState<string>();
  const [severity, setSeverity] = React.useState<NotificationSeverity>();

  const updateMessage = React.useCallback((newMessage: string | undefined, newSeverity: NotificationSeverity) => {
    setMessage(newMessage);
    setSeverity(newSeverity);
  }, []);

  const clearNotification = React.useCallback(() => {
    setMessage(undefined);
  }, []);

  const value = React.useMemo(
    () => ({
      message,
      severity,
      setNotification: updateMessage,
      clearNotification
    }),
    [message, severity, updateMessage, clearNotification]
  );

  return <NotificationContext.Provider value={value}>{props.children}</NotificationContext.Provider>;
};

export default NotificationsProvider;
