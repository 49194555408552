import * as React from "react";
import { PublicClientApplication, AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { AzureAdSettings } from "../api/models";

interface AuthContextType {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: AccountInfo | null;
  getAccessToken?: () => Promise<string | null>;
  login?: () => Promise<void>;
  logout?: () => void;
}

export const AuthContext = React.createContext<AuthContextType>({
  isLoading: false,
  isAuthenticated: false,
  user: null
});

export const useAuth = () => React.useContext(AuthContext);

export const AuthContextProvider: React.FunctionComponent<{ settings: AzureAdSettings }> = (props) => {
  const publicClient = React.useRef(
    new PublicClientApplication({
      auth: {
        authority: `${props.settings.authority}`,
        clientId: props.settings.clientId,
        redirectUri: `${window.location.origin}/authComplete`,
        navigateToLoginRequestUrl: true
      }
    })
  ).current;

  const [isLoading, setIsLoading] = React.useState(true);
  const [user, setUser] = React.useState<AccountInfo | null>(null);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  const scopes = React.useMemo(() => props.settings.scopes.split(" "), [props.settings.scopes]);

  React.useEffect(() => {
    publicClient
      .handleRedirectPromise()
      .then((response) => {
        if (!!response && !!response.account) {
          setUser(response.account);
          setIsAuthenticated(true);
        } else {
          const allAccounts = publicClient.getAllAccounts();
          if (!!allAccounts && allAccounts.length) {
            const account = allAccounts[0];
            setUser(account);
            setIsAuthenticated(true);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        handleError(error);
      });
  }, [publicClient]);

  const login = React.useCallback((): Promise<void> => {
    setIsLoading(true);
    return publicClient.loginRedirect({ scopes }).catch((error) => handleError(error));
  }, [publicClient, scopes]);

  const logout = React.useCallback((): Promise<void> => {
    return publicClient.logout().catch((error) => handleError(error));
  }, [publicClient]);

  const getAccessToken = React.useCallback(async (): Promise<string | null> => {
    try {
      const authenticationResult = await publicClient.acquireTokenSilent({
        account: user!,
        scopes: scopes
      });
      return authenticationResult?.accessToken || null;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        try {
          const tokenResponse = await publicClient.acquireTokenPopup({
            account: user!,
            scopes: scopes
          });
          return tokenResponse?.accessToken || null;
        } catch (tokenPopupError) {
          console.error(tokenPopupError);
        }
      } else {
        console.warn(error);
      }
      return null;
    }
  }, [publicClient, user, scopes]);

  const handleError = (error: any) => {
    console.error("Problem with authentication endpoint: ", error);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        user: user,
        login: login,
        logout: logout,
        getAccessToken
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};
