import { Link as RouterLink } from "react-router-dom";
import { Container, Grid, Link, makeStyles } from "@material-ui/core";
import { FinalPriceBadge, RiskScoreBadge, TeamSizeBadge, TotalHoursBadge } from "./badges";
import EstimateActionBar from "./EstimateActionBar";
import { Estimate } from "../../api/models";

interface EstimateHeaderProps {
  estimate: Estimate;
  onShareRequested?: () => any;
  onCopyRequested?: () => any;
  onDeleteRequested?: () => any;
  hasPendingChanges?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    background: `linear-gradient(94deg, #001614 0%, ${theme.palette.primary.dark} 74.29%, ${theme.palette.primary.main} 81.39%, ${theme.palette.primary.light} 89.81%, ${theme.palette.secondary.main} 102.63%, ${theme.palette.secondary.dark} 102.64%)`,
    marginBottom: 0,
    "&.high-risk": {
      background: "linear-gradient(141deg, #f44336 0%, #ff9800 51%, #ffc107 75%)"
    }
  },
  content: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5)
  },
  client: {
    fontSize: theme.typography.h5.fontSize,
    color: "#fff",
    paddingTop: 0,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column"
  },
  clientLink: {
    color: "inherit"
  },
  name: {
    paddingTop: 0,
    fontSize: theme.typography.h2.fontSize,
    lineHeight: theme.typography.h2.fontSize,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    textDecoration: "none"
  },
  items: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between"
  }
}));

const EstimateHeader = (props: EstimateHeaderProps) => {
  const classes = useStyles();
  const { estimate, onShareRequested, onCopyRequested, onDeleteRequested, hasPendingChanges } = props;
  return (
    <div className={`${classes.root} ${estimate.riskAssessment.score > 3 ? "high-risk" : ""}`}>
      <EstimateActionBar
        estimate={estimate}
        onCopyRequested={onCopyRequested}
        onShareRequested={onShareRequested}
        onDeleteRequested={onDeleteRequested}
        hasPendingChanges={hasPendingChanges}
      />
      <Container maxWidth="lg">
        <div className={classes.content}>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.client}>
              {estimate.client?.clientId && estimate.client.name ? (
                <Link
                  className={classes.clientLink}
                  target="_blank"
                  href={`https://projects.devfacto.com/clients/${estimate.client.clientId}`}>
                  {estimate.client.name}
                </Link>
              ) : (
                estimate.client?.name
              )}
              <Link component={RouterLink} className={classes.name} to={`/estimates/${estimate.id}`}>
                {estimate.name}
              </Link>
            </Grid>
            <Grid item xs={12} className={classes.items}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={4} md={3}>
                  <FinalPriceBadge
                    finalPrice={estimate.plan.finalPrice}
                    contingency={estimate.plan.contingency}
                    discount={estimate.plan.discount}
                  />
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <TotalHoursBadge estimate={estimate} />
                </Grid>
                <Grid item xs={6} sm={2} md={2}>
                  <TeamSizeBadge teamMembers={estimate.plan.teamMembers} />
                </Grid>
                <Grid item xs={6} sm={2} md={2}>
                  <RiskScoreBadge score={estimate.riskAssessment.score} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default EstimateHeader;
