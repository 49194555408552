import * as React from "react";
import { Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tag: {
    fontSize: "13px",
    alignItems: "center"
  }
}));

const TagChip = (props: { name: string }) => {
  const classes = useStyles();
  const { name } = props;
  return <Chip label={name} className={classes.tag} size="small" />;
};

export default TagChip;
