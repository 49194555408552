import * as React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 120,
    padding: theme.spacing(1),
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    color: theme.palette.text.secondary,
    "&.success": {
      background: theme.palette.success.main,
      color: theme.palette.offCommon.black
    },
    "&.info": {
      background: theme.palette.background.paper,
      color: theme.palette.getContrastText(theme.palette.background.paper)
    },
    "&.warning": {
      background: theme.palette.warning.main,
      color: theme.palette.offCommon.black
    },
    "&.error": {
      background: theme.palette.error.main,
      color: theme.palette.offCommon.black
    },
    "&.default": {
      background: theme.palette.type === "dark" ? theme.palette.offCommon.black : theme.palette.offCommon.white,
      color: theme.palette.getContrastText(theme.palette.background.paper)
    }
  },
  title: {},
  main: { flex: 1, display: "flex", alignItems: "center" },
  riskTitle: { color: theme.palette.offCommon.black },
  riskMain: { flex: 1, display: "flex", alignItems: "center", color: theme.palette.offCommon.black },
  subtext: {}
}));
export interface HeaderBadgeProps {
  title: string | JSX.Element;
  text: string | JSX.Element;
  noPadding?: boolean;
  subtext?: string | JSX.Element;
  color?: "success" | "info" | "warning" | "error" | "default";
}

const HeaderBadge: React.FunctionComponent<HeaderBadgeProps> = (props) => {
  const classes = useStyles();
  const { title, text, noPadding, subtext, color } = props;
  return (
    <div className={`${classes.root} ${color || "default"} ${noPadding ? "no-padding" : ""}`}>
      <Typography variant="subtitle2" className={title === "Risk score" ? classes.riskTitle : classes.title}>
        {title}
      </Typography>
      <Typography variant="h3" className={title === "Risk score" ? classes.riskMain : classes.main}>
        {text}
      </Typography>
      <Typography variant="subtitle2" className={title === "Risk score" ? classes.riskTitle : classes.title}>
        {subtext}
      </Typography>
    </div>
  );
};

export default HeaderBadge;
