import { Link } from "react-router-dom";
import { Tooltip, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const EstimateCreateButton = () => {
  return (
    <Link to={`/estimates/new`} className="floating-action-button">
      <Tooltip title="Create a new estimate">
        <Fab variant="circular" color="primary">
          <AddIcon />
        </Fab>
      </Tooltip>
    </Link>
  );
};

export default EstimateCreateButton;
