import { Grid, Fade } from "@material-ui/core";
import { EstimateSummary } from "../../../api/GraphQL/models";
import EstimateListItem from "./EstimateListItem";
export interface FilteredListProps {
  estimates: EstimateSummary[];
}

const FilteredList = (props: FilteredListProps) => {
  const { estimates } = props;

  const sortGroupKeys = (a: string, b: string): number => {
    const aParts = a.split("-");
    const bParts = b.split("-");

    const aYear = parseInt(aParts[0], 10);
    const aMonth = parseInt(aParts[1], 10);

    const bYear = parseInt(bParts[0], 10);
    const bMonth = parseInt(bParts[1], 10);

    if (aYear > bYear) {
      return 1;
    } else if (aYear < bYear) {
      return -1;
    } else {
      if (aMonth > bMonth) {
        return 1;
      } else if (aMonth < bMonth) {
        return -1;
      }
    }
    return 0;
  };

  const group = (estimates: EstimateSummary[]): { [key: string]: EstimateSummary[] } => {
    const grouped: { [key: string]: EstimateSummary[] } = {};
    for (const estimate of estimates) {
      const date = new Date(estimate.dateCreated);
      const key = `${date.getFullYear()}-${date.getMonth() + 1}`;

      if (!grouped[key]) {
        grouped[key] = new Array<EstimateSummary>();
      }

      grouped[key].push(estimate);
    }
    return grouped;
  };

  const getMonthName = (index: number): string => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    return months[index];
  };

  const formatMonth = (monthString: string): string => {
    const parts = monthString.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);

    const now = new Date();
    if (year === now.getFullYear() && month - 1 === now.getMonth()) {
      return "This month";
    } else if (year === now.getFullYear()) {
      return getMonthName(month - 1);
    } else {
      return `${getMonthName(month - 1)} ${year}`;
    }
  };

  const groupedEstimates = group(estimates);
  const groupedKeys = Object.keys(groupedEstimates);
  groupedKeys.sort(sortGroupKeys);
  groupedKeys.reverse();

  return (
    <Grid container spacing={5}>
      {groupedKeys.map((key) => {
        const monthsEstimates = groupedEstimates[key];
        return (
          <Grid key={key} item xs={12}>
            <h3>{formatMonth(key)}</h3>
            <Grid container spacing={2}>
              {monthsEstimates.map((estimate) => {
                return (
                  <Fade in key={estimate.id}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <EstimateListItem key={estimate.id} estimate={estimate} />
                    </Grid>
                  </Fade>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FilteredList;
