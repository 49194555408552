import { formatDistance, format } from "date-fns";
import { EstimateStatus } from "../api/GraphQL/models";

export const formatter = {
  formatEstimateStatus: (status: EstimateStatus) => {
    switch (status) {
      case EstimateStatus.DRAFT:
        return "Draft";
      case EstimateStatus.SUBMITTED_FOR_REVIEW:
        return "Awaiting review";
      case EstimateStatus.RETURNED:
        return "Needs Fixin'";
      case EstimateStatus.APPROVED:
        return "Approved";
    }
  },
  formatCurrency: (amount: number): string => {
    const formattedDollarAmount = formatDollar(amount);
    if (formattedDollarAmount.endsWith(".00")) {
      return formattedDollarAmount.substr(0, formattedDollarAmount.length - 3);
    }
    return formattedDollarAmount;
  },
  formatDate(date: Date | string): string {
    return format(new Date(date), "MMM d, yyyy");
  },
  formatLocalRelativeTime(date: Date | string): string {
    return formatDistance(new Date(), new Date(date)) + " ago";
  }
};

function formatDollar(num: number) {
  const p = num.toFixed(2).split(".");
  const chars = p[0].split("").reverse();
  let newstr = "";
  let count = 0;

  for (const char of chars) {
    count++;
    newstr = count % 3 === 1 && count !== 1 ? char + "," + newstr : char + newstr;
  }
  return newstr + "." + p[1];
}
