import * as React from "react";
import { Grid } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import { v4 as uuid } from "uuid";
import { SettableEstimateItemProps } from "../models";
import { ItemEditor } from "./ItemEditor";
import { EstimateArea, EstimateItemModifier, EstimatePlanTeamMember, EstimateRole } from "../../api/models";

export interface AreaEditorItemsProps {
  area: EstimateArea;
  roles: EstimateRole[];
  teamMembers: EstimatePlanTeamMember[];
  itemModifiers: EstimateItemModifier[];
  setItemProps: (itemId: string, props: SettableEstimateItemProps) => any;
  deleteItem: (itemId: string) => any;
  startItemMove: (itemId: string) => any;
  completeItemMove: (itemId: string) => any;
  stopItemMove: () => any;
}

export class AreaEditorItems extends React.Component<AreaEditorItemsProps> {
  private itemEditorRefs: React.RefObject<ItemEditor>[] = [];

  public shouldComponentUpdate(nextProps: AreaEditorItemsProps) {
    return (
      nextProps.area !== this.props.area ||
      nextProps.roles !== this.props.roles ||
      nextProps.teamMembers !== this.props.teamMembers ||
      nextProps.itemModifiers !== this.props.itemModifiers
    );
  }

  public render() {
    const { area, teamMembers, roles, itemModifiers } = this.props;
    const newItemId = uuid();

    return (
      <Grid container>
        <Grid item xs={12}>
          <table className="table estimate-items full-width">
            <thead>
              <tr>
                <th />
                <th className="name-value-cell" />
                <th />
                <th>Task type</th>
                <th className="group-task-cell">
                  <GroupIcon />
                </th>
                <th className={`range-value-cell highlight`}>ABP</th>
                <th className={`range-value-cell highlight`}>HP</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {area.items
                .concat([
                  {
                    id: newItemId,
                    name: "",
                    roleId: null,
                    description: "",
                    rangeLow: null,
                    rangeHigh: null,
                    isGroupTask: false
                  }
                ])
                .map((item, index) => {
                  const itemEditorRef = React.createRef<ItemEditor>();
                  this.itemEditorRefs[index] = itemEditorRef;
                  return (
                    <ItemEditor
                      ref={itemEditorRef}
                      key={item.id}
                      item={item}
                      teamMembers={teamMembers}
                      onNameKeyDown={this.handleItemNameKeyDown(index)}
                      roles={roles}
                      itemModifiers={itemModifiers}
                      setItemProps={this.setItemProps(item.id)}
                      deleteItem={item.id === newItemId ? undefined : this.deleteItem(item.id)}
                      startItemMove={item.id === newItemId ? undefined : this.startItemMove(item.id)}
                      stopItemMove={item.id === newItemId ? undefined : this.stopItemMove}
                      completeItemMove={this.completeItemMove(item.id)}
                    />
                  );
                })}
            </tbody>
          </table>
        </Grid>
      </Grid>
    );
  }

  private setItemProps = (itemId: string) => (props: SettableEstimateItemProps) => {
    this.props.setItemProps(itemId, props);
  };

  private handleItemNameKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 27) {
      // escape
    } else if (e.keyCode === 38) {
      // up arrow
      const prevItemEditor = this.itemEditorRefs[index - 1].current as ItemEditor;
      if (!!prevItemEditor) {
        prevItemEditor.requestFocus();
      }
    } else if (e.keyCode === 40 || e.keyCode === 13) {
      // down arrow || enter
      const nextItemEditor = this.itemEditorRefs[index + 1].current as ItemEditor;
      if (!!nextItemEditor) {
        nextItemEditor.requestFocus();
      }
    }
  };

  private deleteItem = (itemId: string) => () => {
    this.props.deleteItem(itemId);
  };

  private startItemMove = (itemId: string) => () => {
    this.props.startItemMove(itemId);
  };

  private completeItemMove = (itemId: string) => () => {
    this.props.completeItemMove(itemId);
  };

  private stopItemMove = () => {
    this.props.stopItemMove();
  };
}
