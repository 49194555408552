import * as React from "react";
import { IconButton, Fab, Button, Popover, makeStyles } from "@material-ui/core";
import CommentIcon from "@material-ui/icons/Comment";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block"
  },
  content: {
    padding: theme.spacing(2)
  },
  actions: {
    marginTop: "15px",
    textAlign: "right"
  }
}));

interface ConfirmableActionProps {
  action?: () => any;
  type: "IconButton" | "Button";
  iconName?: "comment" | "delete";
  buttonLabel?: string;
  buttonColor?: "primary" | "inherit" | "secondary";
  buttonVariant?: "fab" | "contained" | "text";
  disabled?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmButtonColor?: "primary" | "inherit" | "secondary";
  className?: string;
  noButtons?: boolean;
  tooltip?: JSX.Element;
  onOpen?: () => any;
}

const ConfirmableAction: React.FunctionComponent<ConfirmableActionProps> = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();

  const onClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (props.onOpen) {
      props.onOpen();
    }
  };
  const handleRequestClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(undefined);
  };

  const handleConfirm = () => {
    if (props.action) {
      props.action();
      setAnchorEl(undefined);
    }
  };

  const isOpen = Boolean(anchorEl);

  return (
    <div className={`${classes.root} ${props.className}`}>
      <div>
        {props.type === "IconButton" ? (
          <IconButton disabled={props.disabled} onClick={onClick}>
            {props.iconName === "comment" ? <CommentIcon /> : <DeleteIcon />}
          </IconButton>
        ) : props.buttonVariant === "fab" ? (
          <Fab variant="circular" disabled={props.disabled} onClick={onClick} color={props.buttonColor || "secondary"}>
            {props.iconName === "comment" ? <CommentIcon /> : <DeleteIcon />}
          </Fab>
        ) : (
          <Button
            variant={props.buttonVariant || "contained"}
            disabled={props.disabled}
            onClick={onClick}
            color={props.buttonColor || "primary"}
            startIcon={props.iconName === "comment" ? <CommentIcon /> : <DeleteIcon />}>
            {props.buttonLabel}
          </Button>
        )}
      </div>
      <Popover open={isOpen} anchorEl={anchorEl} onClose={handleRequestClose}>
        <div className={classes.content}>
          <div>{props.children}</div>
          {props.noButtons ? null : (
            <div className={classes.actions}>
              <Button color={props.confirmButtonColor || "inherit"} onClick={handleConfirm}>
                {props.confirmLabel || "Yes"}
              </Button>
              <Button onClick={handleRequestClose}>{props.cancelLabel || "No"}</Button>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default ConfirmableAction;
