import { useQuery } from "@apollo/client";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import * as React from "react";
import { EstimateStudio, GraphQlConnection } from "../api/GraphQL/models";
import { GET_STUDIOS } from "../api/GraphQL/queries";

export interface StudioSelectorProps {
  value: number[];
  onChanged: (selectedStudios: EstimateStudio[]) => any;
}

export const StudioSelector: React.FunctionComponent<StudioSelectorProps> = (props) => {
  const { data, loading } = useQuery<{ studios: GraphQlConnection<EstimateStudio> }>(GET_STUDIOS);

  const studios = data?.studios.edges.map((e) => e.node) ?? [];

  return (
    <Autocomplete
      autoSelect
      multiple
      value={props.value}
      loading={loading}
      options={studios.map((s) => s.id)}
      getOptionLabel={(value) => studios.find((s) => s.id === value)?.name ?? "[Missing]"}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Studios"
          placeholder={!props.value ? "Studios" : undefined}
          InputProps={{ ...params.InputProps, style: { paddingBottom: 4 } }}
          size="small"
          fullWidth
        />
      )}
      onChange={(event, newValue) =>
        props.onChanged(newValue.map((id) => studios.find((s) => s.id === id) ?? { id: 0, name: "[Missing]" }))
      }
    />
  );
};
