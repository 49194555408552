import { gql } from "@apollo/client";
import { ESTIMATE_DETAILS_FRAGMENT } from "./fragments";

export const UPSERT_ESTIMATE = gql`
  ${ESTIMATE_DETAILS_FRAGMENT}
  mutation UpsertEstimate($estimate: EstimateInput!) {
    estimateUpsert(estimate: $estimate) {
      ...EstimateDetailFields
    }
  }
`;

export const DELETE_ESTIMATE = gql`
  mutation DeleteEstimate($id: String!) {
    estimateDelete(id: $id) {
      id
      succeeded
      message
    }
  }
`;

export const SHARE_ESTIMATE = gql`
  mutation ShareEstimate($input: EstimateSharedKeyInput!) {
    estimateShare(input: $input) {
      estimateId
      key
    }
  }
`;

export const EXPORT_ESTIMATE = gql`
  mutation ExportEstimate($estimateId: String) {
    estimateSpreadsheet(estimateId: $estimateId) {
      estimateId
      url
    }
  }
`;
