import { Typography, makeStyles, Avatar } from "@material-ui/core";
import { PersonDetail } from "../../api/GraphQL/models";
import { formatter } from "../../utils/formatter";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      marginRight: theme.spacing(1)
    }
  },
  fullName: {
    lineHeight: 1.1
  },
  name: {
    "& > *": {
      margin: 0,
      padding: 0
    }
  }
}));

const Revision = (props: { author: Partial<PersonDetail>; date: Date | string; comment?: string }) => {
  const classes = useStyles();
  const { author, date, comment } = props;
  return (
    <div className={classes.root}>
      <Avatar src={`${author.photos?.length ? author.photos[0].uri : "/images/no_photo.png"}`} />
      <div>
        <div className={classes.name}>
          <Typography variant="h6" gutterBottom={false} className={classes.fullName}>
            {author?.fullName || "[unknown]"}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {formatter.formatLocalRelativeTime(date)}
          </Typography>
        </div>
        {comment && (
          <Typography variant="body2" component="p">
            <pre> {comment}</pre>
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Revision;
