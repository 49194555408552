import * as React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedView from "./AuthenticatedView";
import AuthCompletePage from "./pages/AuthCompletePage";
import DashboardPage from "./pages/dashboard";
import EstimateCreatePage from "./pages/create";
import EstimateDetailPage from "./pages/details";
import EditorPage from "./pages/editor";
import PublicPage from "./pages/publicDetails";
import AnonymousView from "./AnonymousView";

export const routes = (
  <Switch>
    <Route exact path="/">
      <AuthenticatedView>
        <DashboardPage />
      </AuthenticatedView>
    </Route>
    <Route exact path="/estimates/new">
      <AuthenticatedView>
        <EstimateCreatePage />
      </AuthenticatedView>
    </Route>
    <Route path="/estimates/:id/edit">
      <AuthenticatedView>
        <EditorPage />
      </AuthenticatedView>
    </Route>

    <Route path="/estimates/:id">
      <AuthenticatedView>
        <EstimateDetailPage />
      </AuthenticatedView>
    </Route>
    <Route path="/shared/estimate/:key">
      <AnonymousView>
        <PublicPage />
      </AnonymousView>
    </Route>
    <Route exact path="/authComplete" component={AuthCompletePage} />
  </Switch>
);
