import * as React from "react";

import { BulkImport, BulkImportArea, BulkImportItem } from "../models";

export interface CsvFileLoaderProps {
  onFileImported: (bulkImport: BulkImport) => any;
}

export class CsvFileLoader extends React.Component<CsvFileLoaderProps> {
  public render() {
    return (
      <div>
        <input type="file" onChange={this.handleFiles} />
      </div>
    );
  }

  private handleFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!!files) {
      // tslint:disable-next-line:no-this-assignment
      const self = this;

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = ((theFile) => {
          return (e: any) => {
            // call the parse function with the proper line terminator and cell terminator
            self.parseCSV(e.target.result, "\n", ";");
          };
        })(file);
        // Read the file as text
        reader.readAsText(file);
      }
    }
  };

  private parseCSV = (text: string, lineTerminator: string, cellTerminator: string) => {
    const bulkImport = {
      areas: new Array<BulkImportArea>()
    } as BulkImport;

    // break the lines apart and remove empty lines
    const lines = this.CSVToArray(text, ",").filter((line) => line.length > 1);

    const areaHash = {} as { [name: string]: BulkImportArea };
    // populate initial areas
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i];
      const name = line[0];
      if (!areaHash[name]) {
        const area = {
          name,
          items: new Array<BulkImportItem>()
        } as BulkImportArea;
        areaHash[name] = area;
        bulkImport.areas.push(area);
      }
    }

    for (let i = 1; i < lines.length; i++) {
      const line = lines[i];
      const areaName = line[0];
      const area = areaHash[areaName];

      const itemName = line[1];
      const itemDescription = line[2] || "";
      const roleId = "e24f0598-9913-40f6-b628-ac16e2e0898d"; // default to dev task id..

      const low = line.length > 3 ? Number(line[3]) : null;
      const high = line.length > 4 ? Number(line[4]) : null;
      const item = {
        name: itemName,
        description: itemDescription,
        roleId: roleId,
        low: low,
        high: high
      } as BulkImportItem;
      area.items.push(item);
    }
    this.props.onFileImported(bulkImport);
  };

  private CSVToArray = (strData: string, strDelimiter: string) => {
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = strDelimiter || ",";

    // Create a regular expression to parse the CSV values.
    const objPattern = new RegExp(
      // Delimiters.
      "(\\" +
        strDelimiter +
        "|\\r?\\n|\\r|^)" +
        // Quoted fields.
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        // Standard fields.
        '([^"\\' +
        strDelimiter +
        "\\r\\n]*))",
      "gi"
    );

    // Create an array to hold our data. Give the array
    // a default empty first row.
    const arrData: string[][] = [[]];

    // Create an array to hold our individual pattern
    // matching groups.
    let arrMatches = null;

    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    // tslint:disable-next-line:no-conditional-assignment
    while ((arrMatches = objPattern.exec(strData))) {
      // Get the delimiter that was found.
      const strMatchedDelimiter = arrMatches[1];

      // Check to see if the given delimiter has a length
      // (is not the start of string) and if it matches
      // field delimiter. If id does not, then we know
      // that this delimiter is a row delimiter.
      if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
        // Since we have reached a new row of data,
        // add an empty row to our data array.
        arrData.push([]);
      }

      let strMatchedValue: string | null = null;

      // Now that we have our delimiter out of the way,
      // let's check to see which kind of value we
      // captured (quoted or unquoted).
      strMatchedValue = !!arrMatches[2]
        ? // We found a quoted value. When we capture
          // this value, unescape any double quotes.
          arrMatches[2].replace(new RegExp('""', "g"), '"')
        : // We found a non-quoted value.
          arrMatches[3];

      // Now that we have our value string, let's add
      // it to the data array.
      arrData[arrData.length - 1].push(strMatchedValue);
    }

    // Return the parsed data.
    return arrData;
  };
}
