import * as React from "react";
import { Dialog, DialogContent, FormControl, FormLabel, Select, MenuItem, TextField, DialogActions, Button } from "@material-ui/core";
import { SimpleChangeLog } from "../models";
import { FormSection, Form, FormField } from "../../layout";
import { Estimate } from "../../api/models";
import { EstimateStatus } from "../../api/GraphQL/models";

export interface EstimateSaveDialogProps {
  isOpen: boolean;
  isFetching: boolean;
  estimate: Estimate;
  changeLog: SimpleChangeLog;
  onSave: (revisionText: string) => any;
  onStatusChanged: (status: EstimateStatus) => any;
  onCancel: () => any;
}

export interface EstimateSaveDialogState {
  changeLog: SimpleChangeLog | null;
  revisionText: string;
}

export class EstimateSaveDialog extends React.Component<EstimateSaveDialogProps, EstimateSaveDialogState> {
  public state: EstimateSaveDialogState = { revisionText: "", changeLog: null };

  public static getDerivedStateFromProps(nextProps: EstimateSaveDialogProps, prevState: EstimateSaveDialogState) {
    return {
      ...prevState,
      revisionText:
        nextProps.changeLog !== prevState.changeLog ? EstimateSaveDialog.buildSimpleChangeLog(nextProps.changeLog) : prevState.revisionText,
      changeLog: nextProps.changeLog
    };
  }

  public render() {
    const { isOpen, isFetching } = this.props;

    return (
      <Dialog open={isOpen}>
        <DialogContent>
          <Form fullWidth>
            <FormSection fullWidth>
              <FormField>
                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <Select
                    value={this.props.estimate.status}
                    onChange={this.onStatusChanged}
                    style={{
                      marginTop: "0"
                    }}>
                    <MenuItem value={EstimateStatus.DRAFT}>Draft</MenuItem>
                    <MenuItem value={EstimateStatus.SUBMITTED_FOR_REVIEW}>Ready for review</MenuItem>
                    <MenuItem value={EstimateStatus.RETURNED}>Needs fixin&apos;</MenuItem>
                    <MenuItem value={EstimateStatus.APPROVED}>Approved</MenuItem>
                  </Select>
                </FormControl>
              </FormField>
              <FormField>
                <FormControl fullWidth>
                  <FormLabel>Changes</FormLabel>
                  <TextField value={this.state.revisionText} onChange={this.onRevisionTextChanged} fullWidth multiline={true} />
                </FormControl>
              </FormField>
            </FormSection>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.props.onCancel} disabled={isFetching}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={this.onSave} disabled={isFetching}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onStatusChanged = (event: object) => {
    const status = (event as any).target.value;
    this.props.onStatusChanged(status);
  };

  private onSave = () => {
    this.props.onSave(this.state.revisionText);
  };

  private onRevisionTextChanged = (event: object) => {
    const text = (event as any).target.value;
    this.setState((s) => ({ ...s, revisionText: text }));
  };

  private static buildSimpleChangeLog(simpleChangelog: SimpleChangeLog): string {
    const changeLogItems = [];
    if (simpleChangelog.changedStatus) {
      changeLogItems.push("- changed status");
    }
    if (simpleChangelog.changedMetadata) {
      changeLogItems.push("- changed about");
    }
    if (simpleChangelog.changedEffort) {
      changeLogItems.push("- changed effort");
    }
    if (simpleChangelog.changedRiskAssessment) {
      changeLogItems.push("- changed risk assessment");
    }
    if (simpleChangelog.changedNotes) {
      changeLogItems.push("- changed notes & assumptions");
    }
    if (simpleChangelog.changedTeam) {
      changeLogItems.push("- changed team");
    }
    if (simpleChangelog.changedContingency) {
      changeLogItems.push("- changed contingency");
    }
    if (simpleChangelog.changedDiscount) {
      changeLogItems.push("- changed discount");
    }
    return changeLogItems.join("\n");
  }
}
