import { Chip } from "@material-ui/core";
import React from "react";
import { EstimateStudio } from "../../api/GraphQL/models";

const EstimateStudiosBadgeList: React.FunctionComponent<{ studios: EstimateStudio[] }> = (props) => {
  const { studios } = props;
  return (
    <div className="tag-list">
      {studios.map((studio) => (
        <Chip label={studio.name} className="tag" />
      ))}
    </div>
  );
};

export default EstimateStudiosBadgeList;
