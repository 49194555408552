import React, { useMemo } from "react";
import { SimpleMdeReact } from "react-simplemde-editor";
import SimpleMDE from "easymde";
import { FormControl, FormLabel } from "@material-ui/core";

const MarkdownEditor = (props: { labelText?: string; value: string; onChange: (value: string) => any }) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const { labelText, value } = props;

  const onFocus = () => setIsFocused(true);

  const onBlur = () => setIsFocused(false);

  const simpleMdeOptions = useMemo(() => {
    return {
      spellChecker: false,
      hideIcons: ["image", "side-by-side", "fullscreen", "guide"],
      status: false
    } as SimpleMDE.Options;
  }, []);

  return (
    <FormControl fullWidth className={`markdown-editor ${isFocused ? "focused" : ""}`}>
      {labelText ? <FormLabel style={{ fontSize: "12px" }}>{labelText}</FormLabel> : null}
      <SimpleMdeReact value={value} onChange={props.onChange} events={{ focus: onFocus, blur: onBlur } as any} options={simpleMdeOptions} />
    </FormControl>
  );
};

export default MarkdownEditor;
