import * as React from "react";
import { Button, ButtonGroup, Grid, Tooltip, makeStyles, Theme } from "@material-ui/core";
import { PageActionBar, StretchedContent } from "../../layout";
import { ConfidenceIndicator } from "../editor/ConfidenceIndicator";
import { formatter } from "../../utils/formatter";
import { TagChip } from "../../misc";
import { Estimate, EstimateRole, EstimateArea, EstimateItem, EstimatePlanTeamMemberType } from "../../api/models";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) => ({
  subTotalHeader: {
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "right",
    fontStyle: "italic",
    color: theme.palette.type === "dark" ? theme.palette.primary.light : theme.palette.primary.dark
  },
  tealText: {
    color: theme.palette.type === "dark" ? theme.palette.primary.light : theme.palette.primary.dark,
    fontSize: "20px",
    textAlign: "right",
    fontWeight: 500
  },
  tealTextHrs: {
    color: theme.palette.type === "dark" ? theme.palette.primary.light : theme.palette.primary.dark,
    fontSize: "16px",
    textAlign: "right",
    fontWeight: 500
  },
  tealTextCost: {
    color: theme.palette.type === "dark" ? theme.palette.primary.light : theme.palette.primary.dark,
    fontSize: "16px",
    textAlign: "right",
    fontWeight: 600
  }
}));

const EstimateSummary = (props: { estimate: Estimate }) => {
  const { estimate } = props;
  const [showItems, setShowItems] = React.useState(true);
  const classes = useStyles();

  const onToggleDetails = (show: boolean) => {
    setShowItems(show);
  };

  if (!estimate || !estimate.plan) {
    return null;
  }

  const areaDict = {} as { [itemId: string]: EstimateArea };
  const itemDict = {} as { [itemId: string]: EstimateItem };

  for (const area of estimate.areas) {
    areaDict[area.id] = area;
    for (const item of area.items) {
      itemDict[item.id] = item;
    }
  }

  const roleHash = {} as { [key: string]: EstimateRole };
  for (const role of estimate.roles) {
    roleHash[role.id] = role;
  }

  const coordinatorTeamMembers = estimate.plan.teamMembers.filter((m) => m.type === EstimatePlanTeamMemberType.COORDINATOR);

  return (
    <div>
      <PageActionBar>
        <ButtonGroup variant="outlined" aria-label="outlined primary button group">
          <Button size="small" variant={!showItems ? "contained" : "outlined"} onClick={() => onToggleDetails(false)}>
            Simple
          </Button>
          <Button size="small" variant={showItems ? "contained" : "outlined"} onClick={() => onToggleDetails(true)}>
            Detailed
          </Button>
        </ButtonGroup>
      </PageActionBar>
      <StretchedContent>
        <div className={`estimate-summary ${showItems ? "detailed" : "simple"}`}>
          {coordinatorTeamMembers.length ? (
            <Grid container spacing={2} style={{ marginTop: 8 }}>
              <Grid item xs={12}>
                <Alert severity="info">{coordinatorTeamMembers.map((m) => m.name).join(", ")} time is blended across tasks.</Alert>
              </Grid>
            </Grid>
          ) : null}
          {estimate.plan.areaTotals.map((areaTotal) => {
            const area = areaDict[areaTotal.areaId];
            return (
              <Grid container spacing={2} className="area" key={areaTotal.areaId}>
                <Grid item xs={12} sm={8} md={9} lg={10}>
                  <header>
                    <span className={classes.tealText}>{area.name || "[unnamed area]"}</span>
                    {!!area.description ? <p className="area-description">{area.description}</p> : null}
                  </header>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={1} className={classes.tealTextHrs}>
                  {Math.round(
                    areaTotal.itemTotals.map((t) => t.totalImplementerHours + t.totalCoordinatorHours).reduce((a, b) => a + b, 0) * 10
                  ) / 10}{" "}
                  hrs
                </Grid>
                <Grid item xs={4} sm={2} md={1} lg={1} className={classes.tealTextCost}>
                  ${formatter.formatCurrency(Math.round(areaTotal.totalCost))}
                </Grid>
                {showItems ? (
                  areaTotal.itemTotals.length ? (
                    areaTotal.itemTotals.map((itemTotal) => {
                      const item = itemDict[itemTotal.itemId];
                      const tooltip = (
                        <div className="summary-item-tooltip">
                          <table>
                            <tbody>
                              <tr>
                                <td>Original:</td>
                                <td>
                                  {item.rangeLow} - {item.rangeHigh}
                                </td>
                              </tr>
                              <tr>
                                <td>+ Factors:</td>
                                <td>
                                  {itemTotal.adjustedRangeLow} - {itemTotal.adjustedRangeHigh}
                                </td>
                              </tr>
                              <tr>
                                <td>Buffer:</td>
                                <td>{Math.round(itemTotal.bufferAmount * 100) / 100}</td>
                              </tr>
                              <tr>
                                <td>Implementation hrs:</td>
                                <td>{Math.round(itemTotal.totalImplementerHours * 100) / 100}</td>
                              </tr>
                              <tr>
                                <td>Blended coordinator hrs:</td>
                                <td>{Math.round(itemTotal.totalCoordinatorHours * 100) / 100}</td>
                              </tr>
                              <tr className="total-row">
                                <td>Total:</td>
                                <td>{Math.round((itemTotal.totalImplementerHours + itemTotal.totalCoordinatorHours) * 100) / 100}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      );
                      return (
                        <Grid item xs={12} className="item" key={itemTotal.itemId}>
                          <Grid container spacing={2}>
                            <Grid item xs={8} sm={5} md={7} lg={8} className="flex-end">
                              <header className="align-self-vertically">
                                <ConfidenceIndicator item={item} />
                                <span className="item-name">{item.name || "[unnamed item]"}</span>
                                {!!item.description ? <p className="item-description">{item.description}</p> : null}
                              </header>
                            </Grid>
                            <Grid item xs={4} sm={3} md={3} lg={2} className="item-role">
                              {item.roleId ? <TagChip name={roleHash[item.roleId].name} /> : null}
                            </Grid>
                            <Grid item xs={4} sm={2} md={1} lg={1} className="flex-end">
                              <Tooltip title={tooltip} placement={"bottom-start"}>
                                <div className="item-hours align-self-vertically">
                                  {Math.round((itemTotal.totalImplementerHours + itemTotal.totalCoordinatorHours) * 10) / 10} hrs
                                </div>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={4} sm={2} md={1} lg={1} className="flex-end">
                              <div className="item-cost align-self-vertically">${formatter.formatCurrency(Math.round(itemTotal.cost))}</div>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })
                  ) : (
                    <Grid item xs={12} className="item items-missing">
                      <header>This area has no items</header>
                    </Grid>
                  )
                ) : null}
              </Grid>
            );
          })}
          <div className="totals">
            {coordinatorTeamMembers.map((member) => {
              return (
                <Grid container key={member.id} className="subtotal">
                  <Grid item xs={8}>
                    <header>
                      <span>{member.name}</span>
                    </header>
                  </Grid>
                  <Grid item xs={4} className={classes.subTotalHeader}>
                    Included in tasks
                  </Grid>
                </Grid>
              );
            })}
            {estimate.plan.discount ? (
              <Grid container className="subtotal">
                <Grid item xs={8}>
                  <header>
                    <span>Subtotal</span>
                  </header>
                </Grid>
                <Grid item xs={4} className="subtotal-cost">
                  ${formatter.formatCurrency(Math.round(estimate.plan.totalCost))}
                </Grid>
              </Grid>
            ) : null}
            {estimate.plan.discount ? (
              <Grid container className="discount">
                <Grid item xs={8}>
                  <header>
                    <span>Discount</span>
                  </header>
                </Grid>
                <Grid item xs={4} className="discount-cost">
                  <span className="negative-number">- ${formatter.formatCurrency(estimate.plan.discount)}</span>
                </Grid>
              </Grid>
            ) : null}
            <Grid container className="total">
              <Grid item xs={8}>
                <header>
                  <span>Total</span>
                </header>
              </Grid>
              <Grid item xs={4} className="total-cost">
                ${formatter.formatCurrency(Math.round(estimate.plan.finalPrice))}
              </Grid>
            </Grid>
          </div>
        </div>
      </StretchedContent>
    </div>
  );
};

export default EstimateSummary;
