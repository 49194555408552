import * as React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { Link, Paper, Table, TableContainer, Typography, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    margin: 0,
    padding: `0 ${theme.spacing(2)}px`
  },
  listItem: {
    marginTop: theme.spacing(0.5)
  },
  header: {
    marginTop: theme.spacing(2)
  }
}));

const MarkdownLink: React.FunctionComponent = (props) => {
  return (
    <Link {...props} target="_blank" color="primary">
      {props.children}
    </Link>
  );
};

function MarkdownParagraph(props: { children: React.ReactNode }) {
  return (
    <Typography variant="body2" component="p" gutterBottom>
      {props.children}
    </Typography>
  );
}

const MarkdownHeading = (props: { level: number; children: React.ReactNode }) => {
  const classes = useStyles();
  let variant;
  switch (props.level) {
    case 1:
      variant = "h5";
      break;
    case 2:
      variant = "h6";
      break;
    case 3:
      variant = "subtitle1";
      break;
    case 4:
      variant = "subtitle2";
      break;
    default:
      variant = "h6";
      break;
  }
  return (
    <Typography className={classes.header} gutterBottom variant={variant as any}>
      {props.children}
    </Typography>
  );
};

const MarkdownList = (props: { children: React.ReactNode }) => {
  const classes = useStyles();
  return <ul className={classes.list}>{props.children}</ul>;
};

const MarkdownListItem = (props: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <li className={classes.listItem}>
      <Typography component="span" variant="body2">
        {props.children}
      </Typography>
    </li>
  );
};

function MarkdownTable(props: { children: React.ReactNode }) {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        {props.children}
      </Table>
    </TableContainer>
  );
}

function MarkdownTableHeaderCell(props: { children: React.ReactNode }) {
  return (
    <TableCell>
      <Typography variant="body1" component="span">
        {props.children}
      </Typography>
    </TableCell>
  );
}

function MarkdownTableCell(props: { children: React.ReactNode }) {
  return (
    <TableCell>
      <Typography variant="body2" component="span">
        {props.children}
      </Typography>
    </TableCell>
  );
}

function MarkdownTableRow(props: { children: React.ReactNode }) {
  return <TableRow>{props.children}</TableRow>;
}

function MarkdownTableBody(props: { children: React.ReactNode }) {
  return <TableBody>{props.children}</TableBody>;
}

function MarkdownTableHead(props: { children: React.ReactNode }) {
  return <TableHead>{props.children}</TableHead>;
}

const components = {
  h1: MarkdownHeading,
  h2: MarkdownHeading,
  h3: MarkdownHeading,
  h4: MarkdownHeading,
  h5: MarkdownHeading,
  h6: MarkdownHeading,
  p: MarkdownParagraph,
  a: MarkdownLink,
  ul: MarkdownList,
  ol: MarkdownList,
  li: MarkdownListItem,
  table: MarkdownTable,
  thead: MarkdownTableHead,
  tbody: MarkdownTableBody,
  tr: MarkdownTableRow,
  td: MarkdownTableCell,
  th: MarkdownTableHeaderCell
};

export default function Markdown(props: ReactMarkdownOptions) {
  return <ReactMarkdown skipHtml={false} {...props} components={components} remarkPlugins={[remarkGfm]} />;
}
