import * as React from "react";
import { Helmet } from "react-helmet";
import { useTelemetry } from "../telemetry/TelemetryProvider";

const DocumentTitle = (props: { pageTitle?: string }) => {
  const pageTitle = `${props.pageTitle || ""}${props.pageTitle ? " | " : ""}Lantern Estimates (devfacto.com)`;
  const { ai } = useTelemetry();
  React.useEffect(() => {
    ai?.trackPageView({
      name: pageTitle
    });
  }, [pageTitle, ai]);
  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};

export default DocumentTitle;
