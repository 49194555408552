import * as React from "react";
import axios from "axios";
import { AppSettings } from "./models";

const useApi = () => {
  const api = React.useMemo(() => {
    return {
      getAppSettings: async () => {
        try {
          const result = await axios.get<AppSettings>(`/api/appSettings`, {});
          if (result.status === 200) {
            return result.data;
          }
          return null;
        } catch (err) {
          return null;
        }
      }
    };
  }, []);
  return {
    api
  };
};

export default useApi;
