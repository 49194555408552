import React from "react";
import { v4 as uuid } from "uuid";
import { EstimateStatus } from "../api/GraphQL/models";
import {
  Estimate,
  EstimateArea,
  EstimateItem,
  EstimatePlanTeamMemberType,
  EstimateRiskAssessmentQuestionAnswer,
  EstimateRiskAssessmentQuestionWeight,
  getEstimateRiskAssessmentQuestionAnswerValue,
  getEstimateRiskAssessmentQuestionWeightValue
} from "../api/models";
import {
  ResetEstimateAction,
  AddTeamMemberAction,
  DeleteTeamMemberAction,
  SetEstimatePropAction,
  SetPlanModifiersAction,
  SetTeamMemberPropsAction,
  AddAreaAction,
  DeleteAreaAction,
  SetAreaPropsAction,
  MoveAreaUpAction,
  AddItemAction,
  DeleteItemAction,
  SetItemPropsAction,
  MoveItemAction,
  DeleteItemModifierAction,
  SetItemModifierPropsAction,
  AddItemModifierAction,
  SetRolePropsAction,
  BulkImportAction,
  SetRiskAssessmentAnswerAction
} from "./estimateEditorActions";
import { SimpleChangeLog } from "./models";
import { PlanCalculator } from "./PlanCalculator";

const defaultRate = 165.0;

export function createEmptyEstimate(): Estimate {
  const devTaskId = "e24f0598-9913-40f6-b628-ac16e2e0898d";
  const archTaskId = "e010b2b4-fe1c-47ae-bfdd-9f0c1bdb1fa1";
  const designTaskId = "2fe7271c-992f-42e3-9fab-7f65a9df7941";
  const testingTaskId = "2aa7a9ea-8b6d-498c-9558-7527f8cae4ff";
  const baTaskId = "c8e844fd-ae41-4cc6-8b55-c619397d8813";
  const workshopTaskId = "d133292a-7a48-404b-ab5e-21ee518edccd";
  const docTaskId = "41324d8e-1844-45d2-a22b-40f282ab4919";

  const estimate = {
    id: uuid(),
    name: "",
    description: "",
    client: null,
    tags: [],
    roles: [
      {
        id: archTaskId,
        name: "Architecture",
        isEnabled: true
      },
      {
        id: devTaskId,
        name: "Development",
        isEnabled: true
      },
      {
        id: designTaskId,
        name: "Design",
        isEnabled: true
      },
      {
        id: testingTaskId,
        name: "Testing",
        isEnabled: true
      },
      {
        id: baTaskId,
        name: "Analysis",
        isEnabled: true
      },
      {
        id: docTaskId,
        name: "Documentation",
        isEnabled: true
      },
      {
        id: workshopTaskId,
        name: "Workshop",
        isEnabled: true
      }
    ],
    itemModifiers: [
      {
        id: uuid(),
        name: "Sprint activities",
        description: "Backlog grooming, scrum meetings, retrospectives, demos",
        percentage: 10,
        rolesAffected: [devTaskId, archTaskId]
      }
    ],
    areas: [
      {
        id: uuid(),
        name: "Project setup",
        description: "",
        items: [
          {
            id: uuid(),
            name: "Set up solution",
            description: "",
            roleId: archTaskId,
            rangeLow: 8,
            rangeHigh: 16,
            isGroupTask: false
          }
        ],
        isExcludedFromPlan: false
      }
    ],
    notes: "",
    assumptions: "",
    riskAssessment: {
      questions: [
        {
          id: "5ee81d44-e89b-4e6e-a6bd-8b6aa87747b8",
          questionText: "Requirements are unknown or unclear",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.MEDIUM
        },
        {
          id: "97853a5f-a0a9-4346-9059-6ac6f1035968",
          questionText: "Requirements were gathered by another party",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.HIGH
        },
        {
          id: "0fd2ff7a-50f7-4165-aeb5-d28c5e34322e",
          questionText: "Client will manage the project",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.MEDIUM
        },
        {
          id: "7600aa02-5ed5-4a85-aa03-6f9356869b42",
          questionText: "Team will work on-site or in another office",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.MEDIUM
        },
        {
          id: "b0c99972-67dd-4bb4-a112-792709eebe2a",
          questionText: "Data migration from existing system required",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.MEDIUM
        },
        {
          id: "24914a04-1d58-427b-be68-a8a7e014de13",
          questionText: "Business process change management required",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.MEDIUM
        },
        {
          id: "514e4d6f-0aab-449e-a451-cd67aad586da",
          questionText: "Part of team will be provided by another party",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.MEDIUM
        },
        {
          id: "aa1e4a9c-2e7c-4ece-bba6-840b3ba3bbea",
          questionText: "User training required",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.LOW
        },
        {
          id: "6a3bb3d3-e21e-48e5-8bcc-ca7f576ed3d2",
          questionText: "Business domain is complex",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.MEDIUM
        },
        {
          id: "9ba55612-eda9-41ff-aa9e-800f7b7510ae",
          questionText: "Customer relationship is strained",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.HIGH
        },
        {
          id: "a326291c-9710-4960-a402-6b488e70a8ea",
          questionText: "Internal processes are complex",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.MEDIUM
        },
        {
          id: "d75ae6f0-752b-4396-89a8-bc6b97f0b486",
          questionText: "Hard deadline",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.MEDIUM
        },
        {
          id: "a9878e98-8db7-425d-b5fc-53c2595876ea",
          questionText: "Fixed cost",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.MEDIUM
        },
        {
          id: "4ef2a9e4-2648-4f46-95db-c5505c6cce09",
          questionText: "Complex integrations required",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.MEDIUM
        },
        {
          id: "67fddb68-396d-42d2-8d1d-f55e2b5a41bd",
          questionText: "Technologies are unfamiliar or new to the team",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.HIGH
        },
        {
          id: "e012883c-0dba-43d4-b7ff-78a1a72ad1e9",
          questionText: "Project will depend on external review or regulations",
          answer: EstimateRiskAssessmentQuestionAnswer.NO,
          weight: EstimateRiskAssessmentQuestionWeight.MEDIUM
        }
      ],
      score: 0
    },
    plan: {
      teamMembers: [
        {
          id: uuid(),
          name: "Architect",
          type: EstimatePlanTeamMemberType.IMPLEMENTER,
          cost: 132.06,
          rate: defaultRate,
          allocation: 90,
          duration: 0,
          assignedHours: 0,
          roleAssignments: [archTaskId, devTaskId]
        },
        {
          id: uuid(),
          name: "Senior Developer",
          type: EstimatePlanTeamMemberType.IMPLEMENTER,
          cost: 120.9,
          rate: defaultRate,
          allocation: 90,
          duration: 0,
          assignedHours: 0,
          roleAssignments: [devTaskId]
        },
        {
          id: uuid(),
          name: "Intermediate Developer",
          type: EstimatePlanTeamMemberType.IMPLEMENTER,
          cost: 110.67,
          rate: defaultRate,
          allocation: 90,
          duration: 0,
          assignedHours: 0,
          roleAssignments: [devTaskId]
        },
        {
          id: uuid(),
          name: "Senior Designer",
          type: EstimatePlanTeamMemberType.IMPLEMENTER,
          cost: 121.83,
          rate: defaultRate,
          allocation: 10,
          duration: 0,
          assignedHours: 0,
          roleAssignments: [designTaskId]
        },
        {
          id: uuid(),
          name: "Intermediate QA",
          type: EstimatePlanTeamMemberType.IMPLEMENTER,
          cost: 95.79,
          rate: defaultRate,
          allocation: 25,
          duration: 0,
          assignedHours: 0,
          roleAssignments: [testingTaskId]
        },
        {
          id: uuid(),
          name: "Business Analyst",
          type: EstimatePlanTeamMemberType.IMPLEMENTER,
          cost: 112.53,
          rate: defaultRate,
          allocation: 25,
          duration: 0,
          assignedHours: 0,
          roleAssignments: [baTaskId]
        },
        {
          id: uuid(),
          name: "Project Manager",
          type: EstimatePlanTeamMemberType.COORDINATOR,
          cost: 137.53,
          rate: defaultRate,
          allocation: 25,
          duration: 0,
          assignedHours: 0,
          roleAssignments: []
        }
      ],
      areaTotals: [],
      contingency: 20,
      discount: 0,
      finalPrice: 0,
      totalCost: 0,
      isValid: true,
      validationMessage: ""
    },
    author: null,
    dateCreated: new Date(),
    studios: [],
    revisions: [],
    status: EstimateStatus.DRAFT
  } as Estimate;

  estimate.plan = PlanCalculator.createPlan(estimate);

  return estimate;
}

export type EstimateEditorReducerAction =
  | ResetEstimateAction
  | AddTeamMemberAction
  | SetTeamMemberPropsAction
  | DeleteTeamMemberAction
  | SetEstimatePropAction
  | SetPlanModifiersAction
  | AddAreaAction
  | MoveAreaUpAction
  | DeleteAreaAction
  | SetAreaPropsAction
  | AddItemAction
  | DeleteItemAction
  | SetItemPropsAction
  | MoveItemAction
  | AddItemModifierAction
  | DeleteItemModifierAction
  | SetItemModifierPropsAction
  | SetRolePropsAction
  | SetRiskAssessmentAnswerAction
  | BulkImportAction;

interface EstimateEditorReducerState {
  estimate: Estimate | null;
  changeLog: SimpleChangeLog;
  isDirty: boolean;
}

export const pristineChangeLog: SimpleChangeLog = {
  changedMetadata: false,
  changedEffort: false,
  changedRiskAssessment: false,
  changedNotes: false,
  changedTeam: false,
  changedContingency: false,
  changedDiscount: false,
  changedStatus: false
};

const omitTypename = (key: any, value: any) => (key === "__typename" ? undefined : value);

export const estimateEditorReducer: React.Reducer<EstimateEditorReducerState, EstimateEditorReducerAction> = (state, action) => {
  switch (action.type) {
    case "RESET_EDITOR": {
      return {
        ...state,
        estimate: JSON.parse(JSON.stringify(action.estimate), omitTypename),
        changeLog: pristineChangeLog,
        isDirty: false
      };
    }
    case "SET_ESTIMATE_PROP": {
      const changedStatus = action.prop === "status" && action.value !== state.estimate?.status;
      const changedMetadata = action.prop === "name" || action.prop === "description" || action.prop === "client" || action.prop === "tags";
      const changedNotes = action.prop === "notes" || action.prop === "assumptions";

      return {
        ...state,
        estimate: {
          ...state.estimate!,
          [action.prop]: action.value
        },
        changeLog: {
          ...state.changeLog,
          changedStatus,
          changedMetadata,
          changedNotes
        },
        isDirty: true
      };
    }
    case "ADD_TEAM_MEMBER": {
      const newEstimate = {
        ...state.estimate!,
        plan: {
          ...state.estimate!.plan,
          teamMembers: state.estimate!.plan.teamMembers.concat([
            {
              id: uuid(),
              type: EstimatePlanTeamMemberType.IMPLEMENTER,
              name: "",
              allocation: 90,
              duration: 0,
              cost: 120,
              rate: defaultRate,
              assignedHours: 0,
              roleAssignments: []
            }
          ])
        }
      };
      newEstimate.plan = PlanCalculator.createPlan(newEstimate);
      return {
        ...state,
        estimate: newEstimate,
        changeLog: {
          ...state.changeLog,
          changedTeam: true
        },
        isDirty: true
      };
    }
    case "SET_TEAM_MEMBER_PROPS": {
      const estimate = {
        ...state.estimate!,
        plan: {
          ...state.estimate!.plan,
          teamMembers: state.estimate!.plan.teamMembers.map((member) => {
            if (member.id === action.teamMemberId) {
              return {
                ...member,
                name: action.props.name,
                type: action.props.type,
                cost: Number(action.props.cost),
                rate: Number(action.props.rate),
                allocation: Number(action.props.allocation),
                duration: Number(action.props.duration),
                roleAssignments: action.props.roleAssignments
              };
            }
            return member;
          })
        }
      };
      estimate.plan = PlanCalculator.createPlan(estimate);

      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedTeam: true
        },
        isDirty: true
      };
    }
    case "DELETE_TEAM_MEMBER": {
      const estimate = {
        ...state.estimate!,
        plan: {
          ...state.estimate!.plan,
          teamMembers: state.estimate!.plan.teamMembers.filter((m) => m.id !== action.teamMemberId)
        }
      };
      estimate.plan = PlanCalculator.createPlan(estimate);

      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedTeam: true
        },
        isDirty: true
      };
    }
    case "SET_PLAN_MODIFIERS": {
      const estimate = {
        ...state.estimate!,
        plan: {
          ...state.estimate!.plan,
          contingency: action.contingency,
          discount: action.discount
        }
      };
      estimate.plan = PlanCalculator.createPlan(estimate);

      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedContingency: state.changeLog.changedContingency || state.estimate!.plan.contingency !== action.contingency,
          changedDiscount: state.changeLog.changedDiscount || state.estimate!.plan.discount !== action.discount
        },
        isDirty: true
      };
    }
    case "ADD_AREA": {
      const estimate = {
        ...state.estimate!,
        areas: state.estimate!.areas.concat([
          {
            id: uuid(),
            name: "",
            description: "",
            items: [],
            isExcludedFromPlan: false
          }
        ])
      };
      estimate.plan = PlanCalculator.createPlan(estimate);

      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedEffort: true
        },
        isDirty: true
      };
    }
    case "SET_AREA_PROPS": {
      let recalcRequired = false;

      const estimate = {
        ...state.estimate!,
        areas: state.estimate!.areas.map((area) => {
          if (area.id === action.areaId) {
            recalcRequired = action.props.isExcludedFromPlan !== area.isExcludedFromPlan;

            return {
              ...area,
              name: action.props.name,
              description: action.props.description,
              isExcludedFromPlan: action.props.isExcludedFromPlan
            };
          }
          return area;
        })
      };

      if (recalcRequired) {
        estimate.plan = PlanCalculator.createPlan(estimate);
      }

      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedEffort: true
        },
        isDirty: true
      };
    }
    case "MOVE_AREA_UP": {
      const areas = [...state.estimate!.areas];
      let indexOfArea = -1;
      for (let i = 0; i < areas.length; i++) {
        const area = areas[i];
        if (area.id === action.areaId) {
          indexOfArea = i;
          break;
        }
      }

      if (indexOfArea > 0) {
        const temp = areas[indexOfArea - 1];
        areas[indexOfArea - 1] = areas[indexOfArea];
        areas[indexOfArea] = temp;
      }

      const estimate = {
        ...state.estimate!,
        areas
      };
      estimate.plan = PlanCalculator.createPlan(estimate);

      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedEffort: true
        },
        isDirty: true
      };
    }
    case "DELETE_AREA": {
      const estimate = {
        ...state.estimate!,
        areas: state.estimate!.areas.filter((area) => area.id !== action.areaId)
      };
      estimate.plan = PlanCalculator.createPlan(estimate);

      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedEffort: true
        },
        isDirty: true
      };
    }
    case "ADD_ITEM": {
      const estimate = {
        ...state.estimate!,
        areas: state.estimate!.areas.map((area) => {
          if (area.id === action.areaId) {
            return {
              ...area,
              items: area.items.concat([
                {
                  id: uuid(),
                  name: "",
                  roleId: null,
                  description: "",
                  rangeLow: null,
                  rangeHigh: null,
                  isGroupTask: false
                }
              ])
            };
          }
          return area;
        })
      };
      estimate.plan = PlanCalculator.createPlan(estimate);

      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedEffort: true
        },
        isDirty: true
      };
    }
    case "DELETE_ITEM": {
      const estimate = {
        ...state.estimate!,
        areas: state.estimate!.areas.map((area) => {
          if (area.id === action.areaId) {
            return {
              ...area,
              items: area.items.filter((item) => item.id !== action.itemId)
            };
          }
          return area;
        })
      };
      estimate.plan = PlanCalculator.createPlan(estimate);

      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedEffort: true
        },
        isDirty: true
      };
    }
    case "SET_ITEM_PROPS": {
      let recalcRequired = false;

      const estimate = {
        ...state.estimate!,
        areas: state.estimate!.areas.map((area) => {
          if (area.id === action.areaId) {
            const isNewItem = !area.items.find((i) => i.id === action.itemId);

            recalcRequired = isNewItem;

            return {
              ...area,
              items: isNewItem
                ? area.items.concat([
                    {
                      id: action.itemId,
                      name: action.props.name,
                      description: action.props.description,
                      roleId: action.props.roleId,
                      rangeLow: action.props.rangeLow,
                      rangeHigh: action.props.rangeHigh,
                      isGroupTask: false
                    }
                  ])
                : area.items.map((item) => {
                    if (item.id === action.itemId) {
                      recalcRequired =
                        item.roleId !== action.props.roleId ||
                        item.rangeLow !== action.props.rangeLow ||
                        item.rangeHigh !== action.props.rangeHigh ||
                        item.isGroupTask !== action.props.isGroupTask;

                      return {
                        ...item,
                        name: action.props.name,
                        description: action.props.description,
                        roleId: action.props.roleId,
                        rangeLow: action.props.rangeLow,
                        rangeHigh: action.props.rangeHigh,
                        isGroupTask: action.props.isGroupTask
                      };
                    }
                    return item;
                  })
            };
          }
          return area;
        })
      };

      if (recalcRequired) {
        estimate.plan = PlanCalculator.createPlan(estimate);
      }

      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedEffort: true
        },
        isDirty: true
      };
    }
    case "MOVE_ITEM": {
      const areaMovingFrom = state.estimate!.areas.find((a) => a.id === action.fromAreaId);
      const itemBeingMoved = areaMovingFrom!.items.find((i) => i.id === action.itemId);

      const newEstimate = {
        ...state.estimate!,
        areas: state.estimate!.areas.map((area) => {
          // we are either moving from this area or to this area, possibly both
          const movingFromArea = area.id === areaMovingFrom!.id;
          const movingToArea = area.id === action.toAreaId;

          if (!movingFromArea && !movingToArea) {
            return area;
          }

          const adjustedArea = {
            ...area
          };

          if (movingFromArea) {
            adjustedArea.items = adjustedArea.items.filter((i) => i.id !== itemBeingMoved!.id);
          }
          // area moving to
          if (movingToArea) {
            // default to end of list for the case that the item is dropped on the new item template
            let insertionIndex = adjustedArea.items.length;
            for (let i = 0; i < adjustedArea.items.length; i++) {
              const checkingItem = adjustedArea.items[i];
              if (checkingItem.id === action.nearItemId) {
                insertionIndex = i;
                break;
              }
            }
            // copy first because splice modifies in place and we are trying to stay immutable
            adjustedArea.items = [...adjustedArea.items];
            adjustedArea.items.splice(insertionIndex, 0, itemBeingMoved!);
          }
          return adjustedArea;
        })
      };

      newEstimate.plan = PlanCalculator.createPlan(newEstimate);

      return {
        ...state,
        estimate: newEstimate,
        changeLog: {
          ...state.changeLog,
          changedEffort: true
        },
        isDirty: true
      };
    }
    case "ADD_ITEM_MODIFIER": {
      const estimate = {
        ...state.estimate!,
        itemModifiers: state.estimate!.itemModifiers.concat([
          {
            id: uuid(),
            name: "",
            description: "",
            percentage: 10,
            rolesAffected: []
          }
        ])
      };
      estimate.plan = PlanCalculator.createPlan(estimate);
      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedEffort: true
        },
        isDirty: true
      };
    }
    case "DELETE_ITEM_MODIFIER": {
      const estimate = {
        ...state.estimate!,
        itemModifiers: state.estimate!.itemModifiers.filter((modifier) => modifier.id !== action.modifierId)
      };
      estimate.plan = PlanCalculator.createPlan(estimate);

      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedEffort: true
        },
        isDirty: true
      };
    }
    case "SET_ITEM_MODIFIER_PROPS": {
      const estimate = {
        ...state.estimate!,
        itemModifiers: state.estimate!.itemModifiers.map((modifier) => {
          if (modifier.id === action.modifierId) {
            return {
              ...modifier,
              name: action.props.name,
              description: action.props.description,
              percentage: action.props.percentage,
              rolesAffected: action.props.rolesAffected
            };
          }
          return modifier;
        })
      };
      estimate.plan = PlanCalculator.createPlan(estimate);
      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedEffort: true
        },
        isDirty: true
      };
    }
    case "SET_ROLE_PROPS": {
      const estimate = {
        ...state.estimate!,
        roles:
          state.estimate?.roles.map((role) => {
            if (role.id === action.roleId) {
              return {
                ...role,
                name: action.props.name,
                isEnabled: action.props.isEnabled
              };
            }
            return role;
          }) || []
      };

      estimate.plan = PlanCalculator.createPlan(estimate);

      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedEffort: true
        },
        isDirty: true
      };
    }
    case "SET_RISK_ASSESSMENT_ANSWER": {
      const newRiskAssessment = {
        ...state.estimate!.riskAssessment,
        questions: state.estimate!.riskAssessment.questions.map((question) => {
          if (question.id === action.questionId) {
            return {
              ...question,
              answer: action.answer
            };
          }
          return question;
        })
      };

      // determine quadrant
      const max = Math.max(
        ...newRiskAssessment.questions.map(
          (q) => getEstimateRiskAssessmentQuestionAnswerValue(q.answer) * getEstimateRiskAssessmentQuestionWeightValue(q.weight)
        )
      );
      newRiskAssessment.score = max + 1;

      return {
        ...state,
        estimate: {
          ...state.estimate!,
          riskAssessment: newRiskAssessment
        },
        changeLog: {
          ...state.changeLog,
          changedRiskAssessment: true
        },
        isDirty: true
      };
    }
    case "BULK_IMPORT": {
      const estimate = {
        ...state.estimate!,
        areas: state.estimate!.areas.concat(
          action.bulkImport.areas.map((a) => {
            return {
              id: uuid(),
              name: a.name,
              items: a.items.map((i) => {
                return {
                  id: uuid(),
                  name: i.name,
                  description: i.description,
                  roleId: i.roleId,
                  rangeLow: i.low,
                  rangeHigh: i.high,
                  isGroupTask: false
                } as EstimateItem;
              }),
              isExcludedFromPlan: false
            } as EstimateArea;
          })
        )
      };

      estimate.plan = PlanCalculator.createPlan(estimate);

      return {
        ...state,
        estimate,
        changeLog: {
          ...state.changeLog,
          changedEffort: true
        },
        isDirty: true
      };
    }
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = action;
  }
  return state;
};
