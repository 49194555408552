import { Link as RouterLink } from "react-router-dom";
import { Card, CardHeader, CardContent, CardActionArea, Typography, makeStyles, Avatar, useTheme } from "@material-ui/core";
import { formatter } from "../../../utils/formatter";
import { EstimateSummary } from "../../../api/GraphQL/models";

const useStyles = makeStyles((theme) => ({
  header: {
    height: 100,
    marginBottom: theme.spacing(2)
  },
  name: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden"
  },
  client: {
    lineHeight: 1.2,
    fontWeight: 600,
    color: theme.palette.type === "dark" ? theme.palette.text.lteal : theme.palette.primary.dark
  },
  price: {
    fontWeight: "lighter"
  }
}));

const EstimateListItem = (props: { estimate: EstimateSummary }) => {
  const theme = useTheme();
  console.log(theme.palette.type);
  const classes = useStyles();
  const { estimate } = props;
  return (
    <Card>
      <CardActionArea component={RouterLink} to={`/estimates/${estimate.id}`}>
        <CardContent>
          <div className={classes.header}>
            <Typography variant="subtitle1" color={estimate.client?.clientId ? "primary" : "textSecondary"} className={classes.client}>
              {estimate.client?.name || "[Unknown client]"}
            </Typography>
            <Typography variant="h5" className={classes.name}>
              {estimate.name || "[no name]"}
            </Typography>
          </div>
          <Typography variant="h4" color="textPrimary" className={classes.price}>
            ${formatter.formatCurrency(estimate.plan.finalPrice)}
          </Typography>
        </CardContent>
        <CardHeader
          avatar={<Avatar src={`${estimate.author?.photos.length ? estimate.author?.photos[0].uri : "/images/no_photo.png"}`} />}
          title={estimate.author?.fullName || "[Unknown author]"}
          subheader={formatter.formatDate(estimate.dateCreated)}
        />
      </CardActionArea>
    </Card>
  );
};

export default EstimateListItem;
