import * as React from "react";
import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 64
  }
}));

const Page: React.FunctionComponent = (props) => {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
};
export default Page;
