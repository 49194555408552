import {
  Estimate,
  EstimatePlan,
  EstimatePlanTeamMemberType,
  EstimateArea,
  EstimateItem,
  EstimateRole,
  EstimatePlanAreaTotal,
  EstimatePlanItemTotal,
  EstimatePlanTeamMember
} from "../api/models";

export const PlanCalculator = {
  createPlan: (estimate: Estimate): EstimatePlan => {
    const validationMessages = [];
    let totalSpread = 0;
    let totalHours = 0;

    const plan = {
      ...estimate.plan,
      areaTotals: [],
      totalCost: 0,
      finalPrice: 0,
      isValid: true,
      validationMessage: ""
    } as EstimatePlan;

    let coordinatorTotalCost = 0;
    let coordinatorTotalHours = 0;

    let coordinatorBlendedRate = 0;

    const coordinators = plan.teamMembers.filter((m) => m.type === EstimatePlanTeamMemberType.COORDINATOR);

    const areaDict = {} as { [itemId: string]: EstimateArea };
    const itemDict = {} as { [itemId: string]: EstimateItem };
    const roleDict = {} as { [roleId: string]: EstimateRole };

    for (const role of estimate.roles) {
      roleDict[role.id] = role;
    }

    for (const coordinator of coordinators) {
      const coordinatorHours = (coordinator.allocation / 100) * 40 * (coordinator.duration || 0);

      //const coordinatorHoursPlusContingency = coordinatorHours * (1 + plan.contingency / 100);
      const coordinatorCost = coordinatorHours * coordinator.rate;

      coordinatorTotalHours += coordinatorHours;
      coordinatorTotalCost += coordinatorCost;
    }
    if (coordinatorTotalHours > 0) {
      coordinatorBlendedRate = coordinatorTotalCost / coordinatorTotalHours;
    }
    // reset team assigned hour amounts
    // as they will be adjusted while we distributed work
    for (const teamMember of plan.teamMembers) {
      teamMember.assignedHours =
        teamMember.type === EstimatePlanTeamMemberType.COORDINATOR ? (teamMember.allocation / 100) * 40 * (teamMember.duration || 0) : 0;
    }

    console.groupCollapsed("Estimate plan calculation");
    console.log(`Blended coordinator rate: ${coordinatorBlendedRate}`);

    console.groupCollapsed("First pass: add multipliers");

    // First pass: add multipliers
    for (const area of estimate.areas) {
      areaDict[area.id] = area;

      if (area.isExcludedFromPlan) {
        continue;
      }

      console.groupCollapsed(`Area: ${area.name}`);

      const areaTotal = {
        areaId: area.id,
        itemTotals: [],
        totalCost: 0
      } as EstimatePlanAreaTotal;

      for (const item of area.items) {
        itemDict[item.id] = item;

        console.groupCollapsed(`Item: ${item.name}`);
        console.log(`Initial range: ${item.rangeLow} - ${item.rangeHigh}`);

        const itemTotal = {
          itemId: item.id,
          adjustedRangeLow: item.rangeLow || 0,
          adjustedRangeHigh: item.rangeHigh || 0,
          spread: 0,
          numberOfImplementers: 1,
          totalImplementerHours: 0,
          totalCoordinatorHours: 0,
          bufferAmount: 0,
          implementerCost: 0,
          coordinatorCost: 0,
          cost: 0
        } as EstimatePlanItemTotal;

        if (item.isGroupTask) {
          const teamMembersWithRole = !!item.roleId ? plan.teamMembers.filter((t) => t.roleAssignments.indexOf(item.roleId!) >= 0) : [];
          if (teamMembersWithRole.length > 1) {
            itemTotal.numberOfImplementers = teamMembersWithRole.length;
          }
        }
        console.log(`${itemTotal.numberOfImplementers} implementers`);

        const applicableModifiers = !!item.roleId ? estimate.itemModifiers.filter((m) => m.rolesAffected.indexOf(item.roleId!) >= 0) : [];

        for (const modifier of applicableModifiers) {
          itemTotal.adjustedRangeLow += (item.rangeLow || 0) * (modifier.percentage / 100);
          itemTotal.adjustedRangeHigh += (item.rangeHigh || 0) * (modifier.percentage / 100);
        }
        console.log(`Adjusted range with modifiers: ${itemTotal.adjustedRangeLow} - ${itemTotal.adjustedRangeHigh}`);

        itemTotal.adjustedRangeLow = itemTotal.adjustedRangeLow * (1 + plan.contingency / 100);
        itemTotal.adjustedRangeHigh = itemTotal.adjustedRangeHigh * (1 + plan.contingency / 100);

        console.log(`Adjusted range with contingency: ${itemTotal.adjustedRangeLow} - ${itemTotal.adjustedRangeHigh}`);

        if (item.isGroupTask) {
          itemTotal.adjustedRangeLow = itemTotal.adjustedRangeLow * itemTotal.numberOfImplementers;
          itemTotal.adjustedRangeHigh = itemTotal.adjustedRangeHigh * itemTotal.numberOfImplementers;

          console.log(`Adjusted range for group task: ${itemTotal.adjustedRangeLow} - ${itemTotal.adjustedRangeHigh}`);
        }

        console.log(`Modified range: ${itemTotal.adjustedRangeLow} - ${itemTotal.adjustedRangeHigh}`);

        itemTotal.spread = Math.pow(itemTotal.adjustedRangeHigh - itemTotal.adjustedRangeLow, 2);

        console.log(`Spread: ${itemTotal.spread}`);

        totalSpread += itemTotal.spread;

        areaTotal.itemTotals.push(itemTotal);

        console.groupEnd();
      }

      console.groupEnd();
      plan.areaTotals.push(areaTotal);
    }
    console.groupEnd();

    console.groupCollapsed("Second pass: calculate buffer");

    console.log(`Total spread: ${totalSpread}`);
    const totalAvailableBuffer = Math.sqrt(totalSpread);

    console.log(`Total buffer: ${totalAvailableBuffer}`);

    for (const areaTotal of plan.areaTotals) {
      const area = areaDict[areaTotal.areaId];

      if (area.isExcludedFromPlan) {
        continue;
      }

      console.groupCollapsed(`Area: ${area.name}`);

      for (const itemTotal of areaTotal.itemTotals) {
        const item = itemDict[itemTotal.itemId];

        console.groupCollapsed(`${item.name}`);
        console.log(`HP: ${itemTotal.adjustedRangeLow}`);

        const percentageOfTotalSpread = totalSpread > 0 ? itemTotal.spread / totalSpread : 0;

        console.log(`% of total spread: ${percentageOfTotalSpread}`);

        const allottedBufferAmount = percentageOfTotalSpread * totalAvailableBuffer;
        itemTotal.bufferAmount = Math.round(allottedBufferAmount * 1000000) / 1000000;

        console.log(`Allotted buffer: ${itemTotal.bufferAmount}`);
        const adjustedTotal = itemTotal.adjustedRangeLow + itemTotal.bufferAmount;

        console.log(`New estimated total: ${adjustedTotal}`);

        itemTotal.totalImplementerHours = adjustedTotal;

        totalHours += itemTotal.totalImplementerHours;

        console.groupEnd();
      }
      console.groupEnd();
    }

    console.groupEnd();

    console.groupCollapsed("Third pass: calculate costs");

    for (const areaTotal of plan.areaTotals) {
      areaTotal.totalCost = 0;
      const area = areaDict[areaTotal.areaId];

      if (area.isExcludedFromPlan) {
        continue;
      }

      console.groupCollapsed(`Area: ${area.name}`);

      for (const itemTotal of areaTotal.itemTotals) {
        itemTotal.cost = 0;

        const item = itemDict[itemTotal.itemId];

        console.groupCollapsed(`Item: ${item.name}`);

        const percentageOfTotal = itemTotal.totalImplementerHours / totalHours;

        console.log(`% of total HP: ${percentageOfTotal}`);

        // simple sort team members with role by amount of work assigned
        // NOTE: naive implementation that assumes tasks are not shared
        const teamMembersWithRole = plan.teamMembers
          .filter((t) => t.roleAssignments.indexOf(item.roleId!) >= 0)
          .sort((a, b) => {
            const aWorkWeeks = a.assignedHours / ((a.allocation / 100) * 40);
            const bWorkWeeks = b.assignedHours / ((b.allocation / 100) * 40);

            if (aWorkWeeks < bWorkWeeks) {
              return -1;
            }
            if (aWorkWeeks > bWorkWeeks) {
              return 1;
            }
            return 0;
          });

        console.log(`Team members in role: ${teamMembersWithRole.length}`);

        if (teamMembersWithRole.length > 0) {
          let teamMembersToAssign: EstimatePlanTeamMember[] = [];

          if (item.isGroupTask) {
            teamMembersToAssign = teamMembersWithRole;
          } else {
            teamMembersToAssign.push(teamMembersWithRole[0]);
          }

          console.log(`Team members assigned: ${teamMembersToAssign.length} (Group task: ${item.isGroupTask})`);

          for (const teamMember of teamMembersToAssign) {
            console.group(`Team member: ${teamMember.id}`);

            const hoursToAssignToTeamMember = itemTotal.totalImplementerHours / teamMembersToAssign.length;

            teamMember.assignedHours += hoursToAssignToTeamMember;

            const implementerCost = Math.round(hoursToAssignToTeamMember * teamMember.rate * 1000000) / 1000000;
            console.log(`Implementer hours assigned: ${hoursToAssignToTeamMember}`);
            console.log(`Implementer rate: ${teamMember.rate}`);
            console.log(`Implementer cost: ${implementerCost}`);

            console.log(`% of total hours: ${percentageOfTotal}`);

            const coordinatorHours = (percentageOfTotal * coordinatorTotalHours) / teamMembersToAssign.length;

            console.log(`Associated coordinator hours: ${coordinatorHours}`);

            itemTotal.totalCoordinatorHours += coordinatorHours;

            itemTotal.implementerCost += implementerCost;

            console.groupEnd();
          }
        } else if (item.roleId != null) {
          plan.isValid = false;
          const message = `NO TEAM MEMBER TO HANDLE TASK: ${roleDict[item.roleId].name}`;
          if (validationMessages.indexOf(message) < 0) {
            validationMessages.push(message);
          }
        }

        itemTotal.totalImplementerHours = Math.round(itemTotal.totalImplementerHours * 1000000) / 1000000;
        itemTotal.implementerCost = Math.round(itemTotal.implementerCost * 1000000) / 1000000;

        itemTotal.totalCoordinatorHours = Math.round(itemTotal.totalCoordinatorHours * 1000000) / 1000000;
        itemTotal.coordinatorCost = Math.round(itemTotal.totalCoordinatorHours * coordinatorBlendedRate * 1000000) / 1000000;

        itemTotal.cost = itemTotal.implementerCost + itemTotal.coordinatorCost;
        areaTotal.totalCost += itemTotal.cost;

        console.log(`Total implementer hours: ${itemTotal.totalImplementerHours}`);
        console.log(`Total implementer cost: ${itemTotal.implementerCost}`);
        console.log(`Total coordinator hours: ${itemTotal.totalCoordinatorHours}`);
        console.log(`Total coordinator cost: ${itemTotal.coordinatorCost}`);

        console.log(`Total cost: ${itemTotal.cost}`);
        console.groupEnd();
      }

      console.groupEnd();
      plan.totalCost += areaTotal.totalCost;
    }

    console.groupEnd();

    plan.finalPrice = plan.totalCost - plan.discount;

    plan.isValid = validationMessages.length === 0;
    plan.validationMessage = validationMessages.join("\n");

    console.groupEnd();

    return plan;
  }
};
