import * as React from "react";
import { Redirect, useParams } from "react-router-dom";
import { Grid, Fade } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import {
  ActionButton,
  AddSharedKeyDialog,
  AreaCostChart,
  EstimateChart,
  EstimateHeader,
  EstimateHeaderPlaceholder,
  EstimateStatusBadge,
  EstimateSummary,
  RevisionHistory,
  RoleHoursChart,
  TeamMemberList
} from "../estimates/detail";
import { ContentContainer, ConstrainedPageContent, NoPrint, Page, PageContent, PageSection, DocumentTitle } from "../layout";
import { Markdown, TagList } from "../misc";
import { Estimate } from "../api/models";
import { useAppSettings } from "../AppSettingsContextProvider";
import { EstimateSharedKeyInput, EstimateShareResult, EstimateStatus } from "../api/GraphQL/models";
import { gql, useMutation, useQuery } from "@apollo/client";
import { GET_ESTIMATE } from "../api/GraphQL/queries";
import { SHARE_ESTIMATE, UPSERT_ESTIMATE } from "../api/GraphQL/mutations";
import { useUserContext } from "../auth/UserContextProvider";
import { useNotifications } from "../notifications/NotificationsProvider";
import { ESTIMATE_DETAILS_FRAGMENT } from "../api/GraphQL/fragments";
import EstimateStudiosBadgeList from "../estimates/detail/EstimateStudiosBadgeList";

export interface EstimateDetailPageState {
  showShareDialog: boolean;
}

const EstimateDetailPage: React.FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const { settings } = useAppSettings();
  const { user } = useUserContext();
  const { setNotification } = useNotifications();

  const [showShareDialog, setShowShareDialog] = React.useState(false);

  const { data, loading: isFetching } = useQuery<{ estimate: Estimate }>(GET_ESTIMATE, {
    variables: {
      id
    }
  });

  const [shareEstimate, { data: sharedKeyData, loading: isSharing }] = useMutation<
    { estimateShare: EstimateShareResult },
    { input: EstimateSharedKeyInput }
  >(SHARE_ESTIMATE);

  const estimate = data?.estimate;

  const addSharedKey = (recipientName: string, password: string) => {
    shareEstimate({
      variables: {
        input: {
          estimateId: id,
          recipientName,
          password
        }
      }
    });
  };

  const onShareRequested = () => {
    setShowShareDialog(true);
  };

  const onShareDialogCloseRequested = () => {
    setShowShareDialog(false);
  };

  const [upsertEstimate, { data: duplicatedEstimate }] = useMutation<{
    estimateUpsert: Estimate;
  }>(UPSERT_ESTIMATE, {
    onCompleted: () => {
      setNotification("Estimate duplicated", "success");
    },
    update: (cache, mutationResponse) => {
      cache.modify({
        fields: {
          estimates(existingItems) {
            const newItemRef = cache.writeFragment({
              data: mutationResponse.data?.estimateUpsert,
              fragment: gql`
                ${ESTIMATE_DETAILS_FRAGMENT}
              `,
              fragmentName: "EstimateDetailFields"
            });
            return !!mutationResponse.data?.estimateUpsert
              ? {
                  ...existingItems,
                  edges: [
                    {
                      node: newItemRef
                    },
                    ...existingItems.edges
                  ]
                }
              : existingItems;
          }
        }
      });
    }
  });

  const duplicateEstimate = async () => {
    if (!!estimate) {
      if (window.confirm("Are you sure you want to duplicate this estimate?")) {
        const estimateCopy: Estimate = {
          ...estimate,
          id: uuid(),
          name: `${estimate.name} - Duplicate`,
          status: EstimateStatus.DRAFT,
          author: {
            id: user?.id,
            fullName: user?.person.fullName
          },
          dateCreated: new Date(),
          revisions: []
        };
        upsertEstimate({
          variables: {
            estimate: JSON.parse(JSON.stringify(estimateCopy), (key: any, value: any) => (key === "__typename" ? undefined : value))
          }
        });
      }
    }
  };

  if (isFetching) {
    return <EstimateHeaderPlaceholder />;
  }

  if (duplicatedEstimate?.estimateUpsert.id) {
    return <Redirect to={`/estimates/${duplicatedEstimate?.estimateUpsert.id}/edit`} />;
  }

  if (!estimate) {
    return null;
  }
  const nameFormat = () => {
    let len = estimate?.client?.name.length;
    let shortName: any = "";
    let CName: any = estimate.client?.name;
    if (estimate?.name !== undefined && len !== undefined && estimate.client !== null) {
      if (CName.includes("(") === true) {
        len = estimate.client.name.lastIndexOf("(");
        CName = estimate.client.name.slice(0, len);
      }
      if (CName.includes(" ") === false || len <= 8) {
        shortName = CName;
        shortName += " - " + estimate?.name;
        return shortName;
      }
      shortName += CName[0];
      for (let i = 1; i < len; i++) {
        if (CName[i] === CName[i].toUpperCase() && CName[i - 1] === " ") {
          shortName += CName[i];
        }
      }
      shortName += " - " + estimate?.name;
      return shortName;
    }
  };
  let name = nameFormat();

  return (
    <Page>
      <DocumentTitle pageTitle={name} />
      <ActionButton estimateId={estimate.id!} />
      <AddSharedKeyDialog
        open={showShareDialog}
        requestClose={onShareDialogCloseRequested}
        addSharedKey={addSharedKey}
        generatedKey={sharedKeyData?.estimateShare?.key}
        isFetching={isSharing}
        appUrl={settings!.appUrl}
      />
      <PageContent>
        <EstimateHeader estimate={estimate} onCopyRequested={duplicateEstimate} onShareRequested={onShareRequested} />
        <Fade in>
          <ConstrainedPageContent>
            <ContentContainer elevation={0}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <PageSection>
                    <EstimateStatusBadge status={estimate.status} />
                  </PageSection>
                </Grid>
                {estimate.studios?.length ? (
                  <Grid item xs={12}>
                    <PageSection>
                      <EstimateStudiosBadgeList studios={estimate.studios} />
                    </PageSection>
                  </Grid>
                ) : null}
                {estimate.tags.length ? (
                  <Grid item xs={12}>
                    <PageSection>
                      <TagList tagNames={estimate.tags.map((t) => t.name)} />
                    </PageSection>
                  </Grid>
                ) : null}
                {estimate.description ? (
                  <Grid item xs={12}>
                    <PageSection>
                      <Markdown children={estimate.description} />
                    </PageSection>
                  </Grid>
                ) : null}
                {!!estimate.notes ? (
                  <Grid item xs={12}>
                    <PageSection title="Notes">
                      <Markdown children={estimate.notes} />
                    </PageSection>
                  </Grid>
                ) : null}
                {!!estimate.assumptions ? (
                  <Grid item xs={12}>
                    <PageSection title="Assumptions">
                      <Markdown children={estimate.assumptions} />
                    </PageSection>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <PageSection>
                    <Grid container spacing={5}>
                      <Grid item xs={12} md={6} lg={6}>
                        <RoleHoursChart estimate={estimate} />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <AreaCostChart estimate={estimate} />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <EstimateChart estimate={estimate} />
                      </Grid>
                    </Grid>
                  </PageSection>
                </Grid>
                <Grid item xs={12}>
                  <PageSection title="Team">
                    <TeamMemberList teamMembers={estimate.plan.teamMembers} roles={estimate.roles} />
                  </PageSection>
                </Grid>
                <Grid item xs={12}>
                  <PageSection title="Items">
                    <EstimateSummary estimate={estimate} />
                  </PageSection>
                </Grid>
                <Grid item xs={12}>
                  <NoPrint>
                    <RevisionHistory estimate={estimate} />
                  </NoPrint>
                </Grid>
              </Grid>
            </ContentContainer>
          </ConstrainedPageContent>
        </Fade>
      </PageContent>
    </Page>
  );
};

export default EstimateDetailPage;
