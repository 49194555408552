import * as React from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  Grid,
  AppBar,
  Toolbar,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  makeStyles,
  Button,
  Typography,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import GetAppIcon from "@material-ui/icons/GetApp";
import ShareIcon from "@material-ui/icons/Share";
import { Estimate } from "../../api/models";
import { useMutation } from "@apollo/client";
import { EXPORT_ESTIMATE } from "../../api/GraphQL/mutations";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 1
  },
  appBar: {
    top: "auto",
    bottom: 0,
    background: "transparent",
    boxShadow: "none",
    maxWidth: "1200px",
    margin: "0 auto",
    height: "40px"
  },
  toolbar: {
    flexDirection: "row-reverse"
  }
}));

interface EstimateActionBarProps {
  estimate: Estimate;
  onShareRequested?: () => any;
  onCopyRequested?: () => any;
  onDeleteRequested?: () => any;
  hasPendingChanges?: boolean;
}

const EstimateActionBar = (props: EstimateActionBarProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();

  const [exportDialogOpen, setExportDialogOpen] = React.useState(false);
  const [exportUrl, setExportUrl] = React.useState<string>();

  const [exportEstimate, { loading: isExporting }] = useMutation<
    { estimateSpreadsheet: { estimateId: string; url: string } },
    { estimateId: string }
  >(EXPORT_ESTIMATE, {
    onCompleted: async (mutationResult) => {
      setExportUrl(mutationResult.estimateSpreadsheet.url);
    }
  });

  const onCloseExportDialog = () => {
    setExportDialogOpen(false);
    setExportUrl(undefined);
  };

  const handleExportClick = () => {
    setExportDialogOpen(true);
    exportEstimate({
      variables: {
        estimateId: props.estimate.id!
      }
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleDelete = () => {
    if (!!props.onDeleteRequested) {
      props.onDeleteRequested();
    }
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <Slide in direction="down">
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <AppBar position="static" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <div>
                <Tooltip title="Download as CSV">
                  <IconButton color="inherit" onClick={handleExportClick} disabled={isExporting || props.hasPendingChanges}>
                    <CloudDownloadIcon />
                  </IconButton>
                </Tooltip>
                <Dialog open={exportDialogOpen} onClose={onCloseExportDialog}>
                  <DialogTitle>Export to Spreadsheet</DialogTitle>
                  <DialogContent>
                    {isExporting ? <Typography color="primary">Generating export...</Typography> : null}
                    {exportUrl ? (
                      <Button
                        variant="contained"
                        startIcon={<GetAppIcon />}
                        component={"a"}
                        href={exportUrl}
                        color="primary"
                        onClick={() => onCloseExportDialog()}>
                        Download
                      </Button>
                    ) : null}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onCloseExportDialog}>Close</Button>
                  </DialogActions>
                </Dialog>
                {props.onShareRequested ? (
                  <Tooltip title="Share externally">
                    <IconButton color="inherit" onClick={props.onShareRequested}>
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {props.onCopyRequested ? (
                  <Tooltip title="Clone to new estimate">
                    <IconButton color="inherit" onClick={props.onCopyRequested}>
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {props.onDeleteRequested ? (
                  <>
                    <IconButton color="inherit" onClick={handleClick}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                      <MenuItem onClick={handleDelete}>DELETE</MenuItem>
                    </Menu>
                  </>
                ) : null}
              </div>
            </Toolbar>
          </AppBar>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default EstimateActionBar;
