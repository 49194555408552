import * as React from "react";
import { Grid } from "@material-ui/core";
import { FormSection, FormField } from "../../layout";
import { SettableRoleProps } from "../models";
import TaskTypeEditor from "./TaskTypeEditor";
import { EstimateRole } from "../../api/models";

const TaskTypesForm: React.FunctionComponent<{ roles: EstimateRole[]; setRoleProps: (roleId: string, props: SettableRoleProps) => any }> = (
  props
) => {
  const onRoleNameChanged = (role: EstimateRole) => (name: string) => {
    props.setRoleProps(role.id, { name, isEnabled: role.isEnabled });
  };

  const onRoleEnabledChanged = (role: EstimateRole) => (isEnabled: boolean) => {
    props.setRoleProps(role.id, { name: role.name, isEnabled });
  };

  return (
    <FormSection title="Task Types" fullWidth>
      <FormField>
        <Grid container spacing={3}>
          {props.roles.map((role) => {
            return (
              <Grid key={role.id} item xs={12} sm={4} md={3}>
                <TaskTypeEditor
                  key={role.id}
                  role={role}
                  onNameChanged={onRoleNameChanged(role)}
                  onEnabledChanged={onRoleEnabledChanged(role)}
                />
              </Grid>
            );
          })}
        </Grid>
      </FormField>
    </FormSection>
  );
};

export default TaskTypesForm;
