import * as React from "react";
import { Estimate, EstimateItem, EstimatePlanTeamMemberType } from "../../api/models";
import { IPieChartDataPoint, IPieChartProps, PieChart } from "../../charts/PieChart";

interface RoleHoursChartProps {
  estimate: Estimate;
}

export class RoleHoursChart extends React.PureComponent<RoleHoursChartProps, {}> {
  private _chart: PieChart | undefined = undefined;
  private _chartElement: HTMLElement | null = null;
  private _resizeDebounceToken: number | undefined = undefined;

  private _roleHourHash = {} as { [key: string]: number };
  private _totalHours = 0;

  private _roleCostHash = {} as { [key: string]: number };
  private _totalDollars = 0;

  private _dataPoints: IPieChartDataPoint[] = [];

  private _colors = ["#00675B", "#0D9B8C", "#FF7F02", "#3CD4BA", "#FFAE00", "#ff5722", "#0D9B8C", "#9e9e9e"];

  public componentDidMount() {
    this._chart = new PieChart(this.getChartState());

    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.handleWindowResize);
    }
  }

  public componentDidUpdate() {
    if (this._chart) {
      this._chart.update(this.getChartState());
    }
  }

  public componentWillUnmount() {
    if (this._chart) {
      this._chart.destroy();
    }

    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.handleWindowResize);
    }
  }

  public render() {
    this.getChartState();

    return (
      <div className="analytics-widget">
        <header>Hours by role</header>
        <div className="body">
          <div className="app-usage">
            <div className="chart-wrapper">
              <div ref={(e) => (this._chartElement = e)} />
            </div>
            <div className="legend">
              <ul>
                {this._dataPoints.map((point, index) => {
                  return (
                    <li key={point.label}>
                      <span className="color-indicator" style={{ background: point.color }} />
                      <span className="label">{point.label}:</span> <span className="value">{point.formattedValue || point.value}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <footer />
      </div>
    );
  }

  private getChartState = (): IPieChartProps => {
    const itemHash = {} as { [key: string]: EstimateItem };
    for (const area of this.props.estimate.areas) {
      for (const item of area.items) {
        itemHash[item.id] = item;
      }
    }

    this._roleHourHash = {} as { [key: string]: number };
    this._totalHours = 0;

    this._roleCostHash = {} as { [key: string]: number };
    this._totalDollars = 0;

    for (const areaTotal of this.props.estimate.plan.areaTotals) {
      for (const itemTotal of areaTotal.itemTotals) {
        const item = itemHash[itemTotal.itemId];
        if (!!item && item.roleId) {
          const roleHourHashValue = (this._roleHourHash[item.roleId] || 0) + itemTotal.totalImplementerHours;
          this._roleHourHash[item.roleId] = roleHourHashValue;

          const roleCostHashValue = (this._roleCostHash[item.roleId] || 0) + itemTotal.cost;
          this._roleCostHash[item.roleId] = roleCostHashValue;

          this._totalHours += itemTotal.totalImplementerHours;
          this._totalDollars += itemTotal.cost;
        }
      }
    }

    const implementerDataPoints = this.props.estimate.roles
      .filter((role) => (this._roleHourHash[role.id] || 0) > 0)
      .map((role, i) => {
        return {
          label: role.name,
          value: Math.round(this._roleHourHash[role.id] || 0),
          color: this._colors[i % this._colors.length]
        } as IPieChartDataPoint;
      });

    const coordinatorDataPoints = this.props.estimate.plan.teamMembers
      .filter((m) => m.type === EstimatePlanTeamMemberType.COORDINATOR && m.assignedHours > 0)
      .map((m, i) => {
        return {
          label: m.name,
          value: Math.round(m.assignedHours),
          color: this._colors[(i + implementerDataPoints.length) % this._colors.length]
        } as IPieChartDataPoint;
      });

    this._dataPoints = implementerDataPoints.concat(coordinatorDataPoints);

    return {
      el: this._chartElement,
      diameter: 150,
      innerDiameter: 100,
      data: this._dataPoints
    } as IPieChartProps;
  };

  private handleWindowResize = () => {
    if (this._resizeDebounceToken != null) {
      window.clearTimeout(this._resizeDebounceToken);
    }
    this._resizeDebounceToken = window.setTimeout(() => {
      this._chart?.update(this.getChartState());
    }, 50);
  };
}
