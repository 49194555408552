import * as React from "react";
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

const apolloClient = new ApolloClient({
  cache: new InMemoryCache()
});

const AnonymousApolloProvider = (props: { uri: string; children: React.ReactNode }) => {
  apolloClient.setLink(
    createHttpLink({
      uri: props.uri
    })
  );
  return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
};

export default AnonymousApolloProvider;
