import * as React from "react";
import { IconButton, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { TagsField, TagValue, NumberInput } from "../../misc";
import { FormSection, FormField } from "../../layout";
import { SettableEstimateItemModifierProps } from "../models";
import { EstimateItemModifier, EstimateRole } from "../../api/models";
import TrimmedTextField from "./TrimmedTextField";

interface ItemModifiersFormProps {
  modifiers: EstimateItemModifier[];
  roles: EstimateRole[];
  addModifier: () => any;
  deleteModifier: (modifieId: string) => any;
  setItemModifierProps: (modifierId: string, props: SettableEstimateItemModifierProps) => any;
}

interface ItemModifiersFormState {
  roles: EstimateRole[];
  roleTags: TagValue[];
}

export class ItemModifiersForm extends React.Component<ItemModifiersFormProps, ItemModifiersFormState> {
  public state: ItemModifiersFormState = { roles: [], roleTags: [] };

  public static getDerivedStateFromProps(nextProps: ItemModifiersFormProps, prevState: ItemModifiersFormState) {
    return nextProps.roles !== prevState.roles
      ? {
          ...prevState,
          roles: nextProps.roles,
          roleTags: nextProps.roles
            ? nextProps.roles.map((role) => {
                return { value: role.id, label: role.name };
              })
            : []
        }
      : prevState;
  }

  public shouldComponentUpdate(nextProps: ItemModifiersFormProps) {
    return nextProps.modifiers !== this.props.modifiers || nextProps.roles !== this.props.roles;
  }

  public render() {
    return (
      <FormSection title="Overhead Factors" fullWidth>
        <FormField>
          <table className="table extra-padding">
            <thead>
              <tr>
                <th />
                <th />
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {this.props.modifiers.map((modifier) => {
                return (
                  <tr key={modifier.id}>
                    <td className="left">
                      <TrimmedTextField value={modifier.name} onChange={this.onModifierPropChanged(modifier, "name")} />
                    </td>
                    <td className="right number-md">
                      <NumberInput value={modifier.percentage} onChange={this.onModifierNumberPropsChanged(modifier, "percentage")} />{" "}
                      <span className="percentage-marker">%</span>
                    </td>

                    <td className="left tag-cell">
                      <TagsField
                        value={modifier.rolesAffected}
                        tags={this.state.roleTags}
                        onValueChanged={this.onRolesAffectedChanged(modifier)}
                      />
                    </td>
                    <td>
                      <IconButton aria-label="Delete" className="area-delete" onClick={this.onModifierDelete(modifier.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </FormField>
        <FormField>
          <div>
            <Button variant="contained" color="primary" onClick={this.props.addModifier}>
              Add factor
            </Button>
          </div>
        </FormField>
      </FormSection>
    );
  }

  private onModifierDelete = (modifierId: string) => () => {
    this.props.deleteModifier(modifierId);
  };

  private onModifierPropChanged = (modifier: EstimateItemModifier, propName: string) => (event: any) => {
    const value = event.target.value as string;
    const newProps = {
      ...modifier,
      [propName]: value
    } as SettableEstimateItemModifierProps;

    this.props.setItemModifierProps(modifier.id, newProps);
  };

  private onModifierNumberPropsChanged = (modifier: EstimateItemModifier, propName: string) => (value: number | null) => {
    const newProps = {
      ...modifier,
      [propName]: value
    } as SettableEstimateItemModifierProps;

    this.props.setItemModifierProps(modifier.id, newProps);
  };

  private onRolesAffectedChanged = (modifier: EstimateItemModifier) => (selectedTags: string[]) => {
    const newProps = {
      ...modifier,
      rolesAffected: selectedTags
    } as SettableEstimateItemModifierProps;

    this.props.setItemModifierProps(modifier.id, newProps);
  };
}
