import * as React from "react";

const useWindowResizeListener = (onChange: () => void) => {
  const resizeDebounceTimeout = 50;
  const resizeDebounceToken = React.useRef<number | undefined>();

  const handleWindowResize = React.useCallback(() => {
    if (resizeDebounceToken.current) {
      window.clearTimeout(resizeDebounceToken.current);
    }
    resizeDebounceToken.current = window.setTimeout(() => {
      onChange();
    }, resizeDebounceTimeout);
  }, [onChange]);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [handleWindowResize]);

  return null;
};

export default useWindowResizeListener;
