import * as React from "react";
import { makeStyles, ThemeProvider, CssBaseline, useMediaQuery } from "@material-ui/core";
import UserContextProvider, { useUserContext } from "./auth/UserContextProvider";
import { GetLanternAppTheme } from "@devfacto/react-components/dist";
import { AppHeader } from "@devfacto/react-components";
import LookupDataProvider from "./lookupData/LookupDataProvider";
import NotificationsProvider from "./notifications/NotificationsProvider";
import Notifications from "./notifications/Notifications";
import { useAppSettings } from "./AppSettingsContextProvider";
import AuthenticatedApolloProvider from "./api/GraphQL/AuthenticatedApolloProvider";
import TelemetryProvider from "./telemetry/TelemetryProvider";

const useStyles = makeStyles((theme) => ({
  main: {}
}));

const AuthenticatedLayout: React.FunctionComponent = (props) => {
  const classes = useStyles();
  const theme = useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light";
  const { settings } = useAppSettings();
  const { user } = useUserContext();
  return (
    <ThemeProvider theme={GetLanternAppTheme({ authenticated: true, type: theme })}>
      <CssBaseline />
      <NotificationsProvider>
        <AuthenticatedApolloProvider uri={`${settings?.apiUrl!}graphql`}>
          <LookupDataProvider>
            <UserContextProvider>
              <TelemetryProvider>
                <Notifications />
                <AppHeader
                  appName="Estimates"
                  currentApp="estimates"
                  user={
                    user
                      ? {
                          id: user.id,
                          photoUri: user.person.photos.length ? user.person.photos[0].uri : undefined
                        }
                      : undefined
                  }
                />
                <main className={classes.main}>
                  <div className="main-content">{props.children}</div>
                </main>
              </TelemetryProvider>
            </UserContextProvider>
          </LookupDataProvider>
        </AuthenticatedApolloProvider>
      </NotificationsProvider>
    </ThemeProvider>
  );
};

export default AuthenticatedLayout;
