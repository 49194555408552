import * as React from "react";
import { Grid, Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    background: theme.palette.type === "dark" ? theme.palette.offCommon.black : theme.palette.offCommon.white
  },
  extraPadding: {
    padding: "8px 16px 16px",
    borderRadius: "4px"
  }
}));

const FormField: React.FunctionComponent<{ /*variant?: "light" | "dark";*/ extraPadding?: boolean }> = (props) => {
  const classes = useStyles();
  const { extraPadding = false } = props;
  return (
    <Grid item xs={12} className={`${classes.root} ${extraPadding ? classes.extraPadding : ""}`}>
      {props.children}
    </Grid>
  );
};
export default FormField;
