export interface PersonDetail {
  id: string;
  givenName: string;
  surname: string;
  fullName: string;
  emailAddress: string;
  photos: PersonPhoto[];
}

export interface PersonPhoto {
  uri: string;
  size: "xs" | "sm" | "md" | "lg" | "xl";
}

export interface EstimateSummary {
  id: string;
  name: string;
  client: EstimateClient | null;
  riskAssessment: EstimateRiskAssessmentSummary;
  plan: EstimatePlanSummary;
  dateCreated: Date | string;
  author: PersonDetail | null;
  status: EstimateStatus;
  studios: EstimateStudio[];
}

export interface EstimateClient {
  clientId: number | null;
  name: string;
}

export interface EstimateStudio {
  id: number;
  name: string;
}

export interface EstimateRiskAssessmentSummary {
  score: number;
}

export interface EstimatePlanSummary {
  teamMembers: EstimatePlanTeamMemberSummary[];
  finalPrice: number;
}

export interface EstimatePlanTeamMemberSummary {
  id: string;
}

export enum EstimateStatus {
  DRAFT = "DRAFT",
  SUBMITTED_FOR_REVIEW = "SUBMITTED_FOR_REVIEW",
  RETURNED = "RETURNED",
  APPROVED = "APPROVED"
}

export interface EstimateSharedKeyInput {
  estimateId: string;
  recipientName: string;
  password: string;
}

export interface EstimateShareResult {
  estimateId: string;
  key: string;
}

export interface GraphQlConnection<T> {
  __typename?: string;
  edges: {
    cursor: string;
    node: T;
  }[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
    endCursor: string;
  };
  totalCount: number;
}

export interface Client {
  id: number;
  name: string;
}
