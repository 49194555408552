import * as React from "react";

const Loading = (props: { text: string }) => {
  return (
    <div className="loading">
      <div>
        <i />
        <i />
        <i />
        <i />
        <i />
      </div>
      <footer>{props.text}</footer>
    </div>
  );
};

export default Loading;
