import { gql } from "@apollo/client";
import { ESTIMATE_DETAILS_FRAGMENT, ESTIMATE_PUBLIC_DETAILS_FRAGMENT, PERSON_DETAILS_FRAGMENT } from "./fragments";

export const GET_TAGS = gql`
  query GetTags {
    estimateTags {
      id
      name
    }
  }
`;

export const GET_ESTIMATES = gql`
  ${PERSON_DETAILS_FRAGMENT}
  query GetEstimates($search: String, $first: Int, $after: String) {
    estimates(search: $search, first: $first, after: $after) {
      edges {
        node {
          id
          name
          client {
            clientId
            name
          }
          riskAssessment {
            score
          }
          plan {
            teamMembers {
              id
            }
            finalPrice
          }
          dateCreated
          author {
            ...PersonDetailsFields
          }
          status
          studios {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export const GET_ESTIMATE = gql`
  ${ESTIMATE_DETAILS_FRAGMENT}
  query GetEstimate($id: String) {
    estimate(id: $id) {
      ...EstimateDetailFields
    }
  }
`;

export const GET_ESTIMATE_PUBLIC = gql`
  ${ESTIMATE_PUBLIC_DETAILS_FRAGMENT}
  query GetEstimatePublic($key: String, $password: String) {
    estimatePublic(key: $key, password: $password) {
      ...EstimatePublicDetailFields
    }
  }
`;

export const GET_CLIENTS = gql`
  query GetClients(
    $search: String
    $forProfitStatus: ClientQueryForProfitStatusFilter
    $businessCategoryIds: [Int!]
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    clients(
      search: $search
      forProfitStatus: $forProfitStatus
      businessCategoryIds: $businessCategoryIds
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      edges {
        cursor
        node {
          id
          name
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const GET_STUDIOS = gql`
  query GetStudios {
    studios(first: 500) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
