import * as React from "react";

import { IPieChartDataPoint, IPieChartProps, PieChart } from "../../charts/PieChart";
import { formatter } from "../../utils/formatter";
import { Estimate, EstimateArea } from "../../api/models";

interface AreaCostChartProps {
  estimate: Estimate;
}

export class AreaCostChart extends React.PureComponent<AreaCostChartProps, {}> {
  private _chart: PieChart | undefined = undefined;
  private _chartElement: HTMLElement | null = null;
  private _resizeDebounceToken: number | undefined = undefined;

  private _dataPoints: IPieChartDataPoint[] = [];

  private _colors = ["#00675B", "#0D9B8C", "#FF7F02", "#3CD4BA", "#FFAE00", "#ff5722", "#0D9B8C", "#9e9e9e"];

  public componentDidMount() {
    this._chart = new PieChart(this.getChartState());

    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.handleWindowResize);
    }
  }

  public componentDidUpdate() {
    if (this._chart) {
      this._chart.update(this.getChartState());
    }
  }

  public componentWillUnmount() {
    if (this._chart) {
      this._chart.destroy();
    }

    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.handleWindowResize);
    }
  }

  public render() {
    this.getChartState();

    return (
      <div className="analytics-widget">
        <header>Area costs</header>
        <div className="body">
          <div className="app-usage">
            <div className="chart-wrapper">
              <div ref={(e) => (this._chartElement = e)} />
            </div>
            <div className="legend">
              <ul>
                {this._dataPoints.map((point, index) => {
                  return (
                    <li key={point.label}>
                      <span className="color-indicator" style={{ background: point.color }} />
                      <span className="label">{point.label}:</span> <span className="value">{point.formattedValue || point.value}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <footer />
      </div>
    );
  }

  private getChartState = (): IPieChartProps => {
    const areaHash = {} as { [key: string]: EstimateArea };
    for (const area of this.props.estimate.areas) {
      areaHash[area.id] = area;
    }

    this._dataPoints = this.props.estimate.plan.areaTotals.map((total, i) => {
      return {
        color: this._colors[i % this._colors.length],
        label: areaHash[total.areaId].name,
        value: total.totalCost,
        formattedValue: `$${formatter.formatCurrency(total.totalCost)}`
      } as IPieChartDataPoint;
    });

    return {
      el: this._chartElement,
      diameter: 150,
      innerDiameter: 100,
      data: this._dataPoints
    } as IPieChartProps;
  };

  private handleWindowResize = () => {
    if (this._resizeDebounceToken != null) {
      window.clearTimeout(this._resizeDebounceToken);
    }
    this._resizeDebounceToken = window.setTimeout(() => {
      this._chart?.update(this.getChartState());
    }, 50);
  };
}
