import { arc, pie, select } from "d3";

export interface IPieChartProps {
  el: any;
  data: IPieChartDataPoint[];
  diameter: number;
  innerDiameter: number;
}

export interface IPieChartDataPoint {
  label: string;
  value: number;
  formattedValue?: string;
  color: string;
}

export class PieChart {
  constructor(props: IPieChartProps) {
    this.update(props);
  }

  public update(props: IPieChartProps) {
    if (!props.el) {
      return;
    }

    const total = props.data.reduce((val, d) => val + d.value, 0);

    const pieChart = pie<IPieChartDataPoint>()
      .value((d) => (d.value / total) as number)
      .sort(null)
      .padAngle(0.03);

    const w = props.diameter;
    const h = props.diameter;

    const outerRadius = w / 2;
    const innerRadius = props.innerDiameter / 2;

    const initialArc = arc<IPieChartDataPoint>().outerRadius(outerRadius).innerRadius(innerRadius);

    select(props.el).select("svg").remove();

    const svg = select(props.el)
      .append("svg")
      .attr("width", w)
      .attr("height", h)
      .attr("class", "shadow")
      .append("g")
      .attr("transform", "translate(" + w / 2 + "," + h / 2 + ")");

    svg
      .selectAll("path")
      .data(pieChart(props.data))
      .enter()
      .append("path")
      .attr("d", initialArc as any)
      .attr("fill", (d: any, i) => d.data.color);
  }

  public destroy() {
    // Any clean-up would go here
    // in this example there is nothing to do
  }
}
