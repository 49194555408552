import { useQuery } from "@apollo/client";
import * as React from "react";
import { EstimateTag } from "../api/models";
import { GET_TAGS } from "../api/GraphQL/queries";

interface LookupDataContextValue {
  tags: EstimateTag[] | undefined;
}

export const LookupDataContext = React.createContext<LookupDataContextValue>({ tags: undefined });
export const useLookupData = () => React.useContext(LookupDataContext);

const LookupDataProvider: React.FunctionComponent = (props) => {
  const { data } = useQuery<{ estimateTags: EstimateTag[] }>(GET_TAGS);

  const tags = data?.estimateTags;

  return <LookupDataContext.Provider value={{ tags }}>{props.children}</LookupDataContext.Provider>;
};

export default LookupDataProvider;
