import { Typography } from "@material-ui/core";

const PageSectionHeader = (props: { title: string }) => {
  return (
    <Typography variant="h2" gutterBottom>
      {props.title}
    </Typography>
  );
};

export default PageSectionHeader;
