import * as React from "react";
import { TextField } from "@material-ui/core";

export interface NumberInputProps {
  label?: string;
  className?: string;
  fullWidth?: boolean;
  value: number | null;
  error?: boolean;

  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;

  onChange: (value: number | null) => any;
}

export interface NumberInputState {
  workingValue: string;
}

export class NumberInput extends React.Component<NumberInputProps, NumberInputState> {
  public state: NumberInputState = { workingValue: "" };

  public componentDidMount() {
    this.setState({
      workingValue: this.props.value != null ? this.props.value.toString() : ""
    });
  }

  public shouldComponentUpdate(nextProps: NumberInputProps, nextState: { workingValue: string }) {
    return (
      this.props.label !== nextProps.label ||
      this.props.className !== nextProps.className ||
      this.props.fullWidth !== nextProps.fullWidth ||
      this.props.error !== nextProps.error ||
      this.state.workingValue !== nextState.workingValue
    );
  }

  public render() {
    const { startAdornment, endAdornment, label, className, fullWidth, error } = this.props;

    const inputProps =
      startAdornment || endAdornment
        ? {
            startAdornment,
            endAdornment
          }
        : undefined;

    return (
      <TextField
        label={label}
        className={className}
        fullWidth={fullWidth}
        value={this.state.workingValue}
        onChange={this.onChange}
        error={!this.isValidInteger(this.state.workingValue) || error}
        onBlur={this.onBlur}
        InputProps={inputProps}
      />
    );
  }

  private isValidInteger = (value: string): boolean => {
    const numberValue = Number((value || "").trim());
    const notANumber = isNaN(numberValue);

    if (notANumber) {
      return false;
    } else if (value.indexOf(".") >= 0) {
      return false;
    } else if (numberValue < 0) {
      return false;
    }

    return true;
  };

  private onChange = (event: any) => {
    const newWorkingValue = event.target.value as string;

    const numberValue = newWorkingValue.trim() !== "" ? Number(newWorkingValue) : null;

    /*
            If it is indeed a number and a number different than the current props, call onChange.
            This prevents the editor from changing the value when we enter a decimal.
        */
    if ((numberValue == null || this.isValidInteger(newWorkingValue)) && numberValue !== this.props.value) {
      this.props.onChange(numberValue);
    }

    this.setState({
      workingValue: newWorkingValue
    });
  };

  private onBlur = () => {
    /* Reset state to match the props value to ensure field value matches stored value */
    this.setState({
      workingValue: this.props.value != null ? this.props.value.toString() : ""
    });
    this.forceUpdate();
  };
}
