import HeaderBadge from "./headerBadge";
import { formatter } from "../../../utils/formatter";

export interface FinalPriceBadgeProps {
  finalPrice: number;
  contingency?: number;
  discount: number;
  isPublicFacing?: boolean;
}

const FinalPriceBadge = (props: FinalPriceBadgeProps) => {
  const { finalPrice, contingency, discount, isPublicFacing } = props;
  return (
    <HeaderBadge
      title="Estimated price"
      text={<span>${formatter.formatCurrency(Math.round(finalPrice))}</span>}
      subtext={
        <span>
          {!isPublicFacing ? (
            <span>
              Contingency: {contingency}
              %,&nbsp;
            </span>
          ) : null}
          {!isPublicFacing || discount > 0 ? <span>Discount: ${formatter.formatCurrency(discount)}</span> : null}
        </span>
      }
    />
  );
};

export default FinalPriceBadge;
