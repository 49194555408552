import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: "25px",

    a: {
      textDecoration: "none"
    },
    "&.left": {
      textAlign: "left"
    },
    "&.right": {
      textAlign: "right"
    },
    "@media print": {
      display: "none"
    }
  }
}));

const PageActionBar: React.FunctionComponent<{ align?: "left" | "right"; className?: string }> = (props) => {
  const classes = useStyles();
  return <div className={`${classes.root} ${props.align || "left"} ${props.className}`}>{props.children}</div>;
};

export default PageActionBar;
