import * as React from "react";
import { FormControl, InputLabel, Select, Input, Chip, MenuItem } from "@material-ui/core";

export interface TagValue {
  value: string;
  label: string;
}

export interface TagsFieldProps {
  isDisabled?: boolean;
  floatingLabelText?: string;
  value: string[];
  tags: TagValue[];
  onValueChanged: (selectedTags: string[]) => any;
  errorText?: string;
}

export class TagsField extends React.Component<TagsFieldProps> {
  public shouldComponentUpdate(nextProps: TagsFieldProps) {
    return (
      nextProps.isDisabled !== this.props.isDisabled ||
      nextProps.floatingLabelText !== this.props.floatingLabelText ||
      nextProps.value !== this.props.value ||
      nextProps.tags !== this.props.tags ||
      nextProps.errorText !== this.props.errorText
    );

    // we ignore onValueChanged because we don't need to render when the fn changes
  }

  public render() {
    const { tags, floatingLabelText } = this.props;
    return (
      <>
        <FormControl fullWidth error={!!this.props.errorText}>
          {floatingLabelText ? <InputLabel htmlFor="select-multiple-chip">{floatingLabelText}</InputLabel> : null}
          <Select
            multiple
            value={this.props.value}
            onChange={this.handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div>
                {(selected as string[]).map((value) => {
                  const tag = tags.find((t) => t.value === value);
                  return <Chip key={value} label={tag ? tag.label : "[missing]"} onDelete={this.handleDelete(value)} />;
                })}
              </div>
            )}>
            {tags.map((tag) => (
              <MenuItem key={tag.value} value={tag.value}>
                {tag.label}
              </MenuItem>
            ))}
          </Select>
          {this.props.errorText ? <div className="form-error">{this.props.errorText}</div> : null}
        </FormControl>
      </>
    );
  }

  private handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.props.onValueChanged(event.target.value as string[]);
  };

  private handleDelete = (value: string) => () => {
    this.props.onValueChanged(this.props.value.filter((t) => t !== value));
  };
}
