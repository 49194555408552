import * as React from "react";

export interface PlaceholderBoxProps {
  color?: "extralight" | "light" | "dark";
  size?: "small" | "large" | "full";
  borderRadius?: string;
  className?: string;
  children?: React.ReactNode;
}

const PlaceholderBox = (props: PlaceholderBoxProps) => {
  const { color = "light", size = "small", borderRadius = "none", className = "" } = props;

  return (
    <div className={`placeholder-box ${color} ${size} ${className}`} style={{ borderRadius }}>
      {props.children}
    </div>
  );
};

export default PlaceholderBox;
