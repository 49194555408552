import * as React from "react";
import { List, Button, Typography } from "@material-ui/core";
import { TeamMemberEditor } from "./TeamMemberEditor";
import { ContentContainer, Form, FormSection, FormField, PageActionBar } from "../../layout";
import { SettableEstimatePlanTeamMemberProps } from "../models";
import { EstimatePlanTeamMember, EstimateRole } from "../../api/models";

interface TeamFormProps {
  roles: EstimateRole[];
  teamMembers: EstimatePlanTeamMember[];
  addTeamMember: () => any;
  deleteTeamMember: (teamMemberId: string) => any;
  setTeamMemberProps: (teamMemberId: string, props: SettableEstimatePlanTeamMemberProps) => any;
}

export class TeamForm extends React.Component<TeamFormProps> {
  public shouldComponentUpdate(nextProps: TeamFormProps) {
    return nextProps.roles !== this.props.roles || nextProps.teamMembers !== this.props.teamMembers;

    // we ignore the fns as we don't need to render when this changes
  }

  public render() {
    const { roles } = this.props;

    const memberDurations: { [id: string]: number } = {};
    let longestMemberDuration = 0;
    for (const member of this.props.teamMembers) {
      const duration = member.assignedHours / ((member.allocation / 100) * 40) || 0;
      if (duration > longestMemberDuration) {
        longestMemberDuration = duration;
      }
      memberDurations[member.id] = duration;
    }

    return (
      <ContentContainer elevation={0}>
        <Form fullWidth>
          <FormSection title="Team" fullWidth>
            <FormField>
              <Typography variant="caption">
                This section is a best guess of the team required for this project, not a commitment. The timeline only gives a rough
                approximation of longest path and required duration for each task-based team member and should not be used verbatim as a
                project plan.
              </Typography>
            </FormField>
            <FormField extraPadding>
              <List className="team">
                {this.props.teamMembers.map((member) => {
                  const weeks = memberDurations[member.id];
                  const width = (weeks / longestMemberDuration) * 100;

                  return (
                    <TeamMemberEditor
                      key={member.id}
                      member={member}
                      duration={memberDurations[member.id]}
                      relativeUtilization={width}
                      roles={roles}
                      setTeamMemberProps={this.props.setTeamMemberProps}
                      deleteTeamMember={this.props.deleteTeamMember}
                    />
                  );
                })}
              </List>
              <PageActionBar>
                <Button onClick={this.props.addTeamMember} variant="contained" color="primary">
                  Add team member
                </Button>
              </PageActionBar>
            </FormField>
          </FormSection>
        </Form>
      </ContentContainer>
    );
  }
}
