import * as React from "react";
import { Radio } from "@material-ui/core";
import { ContentContainer, Form, FormSection, FormField } from "../../layout";
import {
  EstimateRiskAssessment,
  EstimateRiskAssessmentQuestion,
  EstimateRiskAssessmentQuestionAnswer,
  getEstimateRiskAssessmentQuestionAnswerValue,
  getEstimateRiskAssessmentQuestionWeightValue
} from "../../api/models";

interface RiskAssessmentFormProps {
  riskAssessment: EstimateRiskAssessment;
  setRiskAssessmentAnswer: (questionId: string, answer: EstimateRiskAssessmentQuestionAnswer) => any;
}

export class RiskAssessmentForm extends React.PureComponent<RiskAssessmentFormProps> {
  public render() {
    return (
      <ContentContainer elevation={0}>
        <Form fullWidth>
          <FormSection title="Risk Assessment" fullWidth>
            <FormField>
              <table className="table full-width stripe">
                <thead>
                  <tr>
                    <th />
                    <th>No</th>
                    <th>Yes</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.props.riskAssessment.questions.map((question) => {
                    const heatmapClass = this.getHeatmapClass(question);

                    return (
                      <tr key={question.id}>
                        <td>{question.questionText}</td>
                        <td
                          className={`center ${heatmapClass} ${
                            question.answer === EstimateRiskAssessmentQuestionAnswer.NO ? "" : "muted"
                          }`}>
                          <Radio
                            checked={question.answer === EstimateRiskAssessmentQuestionAnswer.NO}
                            onClick={this.setRiskAssessmentAnswer(question.id, EstimateRiskAssessmentQuestionAnswer.NO)}
                            color="primary"
                          />
                        </td>
                        <td
                          className={`center ${heatmapClass} ${
                            question.answer === EstimateRiskAssessmentQuestionAnswer.YES ? "" : "muted"
                          }`}>
                          <Radio
                            checked={question.answer === EstimateRiskAssessmentQuestionAnswer.YES}
                            onClick={this.setRiskAssessmentAnswer(question.id, EstimateRiskAssessmentQuestionAnswer.YES)}
                            color="primary"
                          />
                        </td>
                        <td />
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </FormField>
          </FormSection>
        </Form>
      </ContentContainer>
    );
  }

  private setRiskAssessmentAnswer = (questionId: string, answer: EstimateRiskAssessmentQuestionAnswer) => () => {
    this.props.setRiskAssessmentAnswer(questionId, answer);
  };

  private getHeatmapClass = (question: EstimateRiskAssessmentQuestion): string => {
    const adjustedValue =
      getEstimateRiskAssessmentQuestionAnswerValue(question.answer) * getEstimateRiskAssessmentQuestionWeightValue(question.weight);

    switch (adjustedValue) {
      case 0:
        return "no-risk";
      case 1:
        return "low-risk";
      case 2:
        return "medium-risk";
      case 3:
        return "high-risk";
      default:
        return "no-risk";
    }
  };
}
