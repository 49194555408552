import * as React from "react";
import { AccordionDetails, Accordion, AccordionSummary } from "@material-ui/core";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import { SettableEstimateAreaProps, SettableEstimateItemProps } from "../models";
import { AreaEditorSummary } from "./AreaEditorSummary";
import { AreaEditorItems } from "./AreaEditorItems";
import { EstimateArea, EstimateItemModifier, EstimatePlanAreaTotal, EstimatePlanTeamMember, EstimateRole } from "../../api/models";

interface AreaEditorProps {
  roles: EstimateRole[];
  teamMembers: EstimatePlanTeamMember[];
  itemModifiers: EstimateItemModifier[];
  area: EstimateArea;
  areaTotal: EstimatePlanAreaTotal;
  deleteArea: () => any;
  setProps: (props: SettableEstimateAreaProps) => any;
  addItem: () => any;
  setItemProps: (itemId: string, props: SettableEstimateItemProps) => any;
  deleteItem: (itemId: string) => any;
  moveUp: () => any;
  startItemMove: (itemId: string) => any;
  stopItemMove: () => any;
  completeItemMove: (itemId: string) => any;
}

export interface AreaEditorState {
  isExpanded: boolean;
}

export class AreaEditor extends React.Component<AreaEditorProps, AreaEditorState> {
  public state: AreaEditorState = { isExpanded: true };

  public shouldComponentUpdate(nextProps: AreaEditorProps, nextState: AreaEditorState) {
    return (
      nextProps.area !== this.props.area ||
      nextProps.areaTotal !== this.props.areaTotal ||
      nextProps.roles !== this.props.roles ||
      nextProps.teamMembers !== this.props.teamMembers ||
      nextProps.itemModifiers !== this.props.itemModifiers ||
      nextState.isExpanded !== this.state.isExpanded
    );
  }

  public render() {
    const { area, roles, teamMembers, itemModifiers, areaTotal } = this.props;

    return (
      <Accordion
        expanded={this.state.isExpanded}
        onChange={this.toggleExpand}
        className={`area-editor ${area.isExcludedFromPlan ? "excluded" : ""}`}>
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <AreaEditorSummary
            area={area}
            areaTotal={areaTotal}
            moveUp={this.props.moveUp}
            deleteArea={this.props.deleteArea}
            toggleExclude={this.toggleExclude}
            onNameChange={this.onNameChange}
            onDescriptionChange={this.onDescriptionChange}
          />
        </AccordionSummary>
        <AccordionDetails className="estimate-area">
          <AreaEditorItems
            area={area}
            roles={roles}
            teamMembers={teamMembers}
            itemModifiers={itemModifiers}
            setItemProps={this.props.setItemProps}
            startItemMove={this.props.startItemMove}
            stopItemMove={this.props.stopItemMove}
            completeItemMove={this.props.completeItemMove}
            deleteItem={this.props.deleteItem}
          />
        </AccordionDetails>
      </Accordion>
    );
  }

  private toggleExpand = () => {
    this.setState((s) => ({ ...s, isExpanded: !s.isExpanded }));
  };

  private toggleExclude = () => {
    const props = {
      ...this.props.area,
      isExcludedFromPlan: !this.props.area.isExcludedFromPlan
    };
    this.props.setProps(props);
  };

  private onNameChange = (name: string) => {
    const props = {
      ...this.props.area,
      name
    };
    this.props.setProps(props);
  };

  private onDescriptionChange = (description: string) => {
    const props = {
      ...this.props.area,
      description
    };
    this.props.setProps(props);
  };
}
