import * as React from "react";
import { useQuery } from "@apollo/client";
import { Waypoint } from "react-waypoint";
import { Grid, makeStyles } from "@material-ui/core";
import EstimateList from "../estimates/list/components/EstimateList";
import { Page, PageContent, ConstrainedPageContent } from "../layout";
import { EstimateCreateButton } from "../estimates/create";
import EstimateListPlaceholder from "../estimates/list/components/EstimateListPlaceholder";
import { EstimateSummary, GraphQlConnection } from "../api/GraphQL/models";
import { GET_ESTIMATES } from "../api/GraphQL/queries";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: theme.spacing(4)
  }
}));

const pageSize = 20;

const DashboardPage = () => {
  const classes = useStyles();

  const [currentSearchFilter, setCurrentSearchFilter] = React.useState("");

  const {
    data,
    loading: isFetching,
    fetchMore
  } = useQuery<{ estimates: GraphQlConnection<EstimateSummary> }>(GET_ESTIMATES, {
    variables: {
      search: currentSearchFilter,
      first: pageSize,
      after: null
    }
  });

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        first: pageSize,
        after: data?.estimates!.pageInfo.endCursor
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult!.estimates.edges;
        return newEdges.length
          ? {
              // Put the new items at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              estimates: {
                __typename: previousResult.estimates.__typename,
                edges: [...previousResult.estimates.edges, ...newEdges],
                pageInfo: fetchMoreResult!.estimates.pageInfo,
                totalCount: fetchMoreResult!.estimates.totalCount
              }
            }
          : previousResult;
      }
    });
  };

  const onSearchFilterChange = (searchFilter: string) => {
    if (searchFilter !== currentSearchFilter) {
      setCurrentSearchFilter(searchFilter);
    }
  };

  const estimates = data?.estimates.edges.map((e) => e.node) || [];

  return (
    <Page>
      <PageContent>
        <ConstrainedPageContent>
          <Grid container spacing={1} className={classes.content}>
            <Grid item xs={12}>
              <EstimateList estimates={estimates} isFetching={isFetching} onFilterChange={onSearchFilterChange} />
            </Grid>
            {!isFetching && !!data?.estimates.pageInfo.hasNextPage && !!data?.estimates.pageInfo.endCursor && (
              <Grid item xs={12}>
                <Waypoint key={estimates.length} onEnter={handleLoadMore} />
                <EstimateListPlaceholder />
              </Grid>
            )}
          </Grid>
        </ConstrainedPageContent>
        <EstimateCreateButton />
      </PageContent>
    </Page>
  );
};

export default DashboardPage;
