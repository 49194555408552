import * as React from "react";
import { Grid, Card, CardContent, Theme, makeStyles } from "@material-ui/core";
import { PlaceholderBox, PlaceholderLine } from "../../../misc";

import randomName from "wizard_name_generator";

const useStyles = makeStyles((theme: Theme) => ({
  placeholderEmoji: {
    fontSize: 34,
    opacity: 0.7
  },
  placeholderName: {
    opacity: 0.7
  }
}));

const EstimateListPlaceholderItem: React.FunctionComponent<{ id: number }> = (props) => {
  const classes = useStyles();
  const { id } = props;

  const pickAnEmoiji = (index: number): JSX.Element => {
    const emojis = ["🐼", "🐷", "🦁", "🐨", "🐶", "🦊", "🐻", "🐸", "🦄", "🙊", "🙉", "🐹", "🐮", "🐱", "🐰"];
    return <span className={classes.placeholderEmoji}>{emojis[index % emojis.length]}</span>;
  };

  let names: string[] = [];
  const getName = (index: number): JSX.Element => {
    if (!names[index]) {
      names[index] = randomName.randomWizardName();
    }
    return <span className={classes.placeholderName}>{names[index]}</span>;
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PlaceholderLine thickness="thick" />
            <PlaceholderLine thickness="thick" />
          </Grid>
          <Grid item xs={12}>
            <PlaceholderLine thickness="thick" width="60%" />
            <PlaceholderLine thickness="thick" width="40%" />
          </Grid>
          <Grid item xs={2}>
            <PlaceholderBox borderRadius="50%" size="large" color="extralight">
              {pickAnEmoiji(id)}
            </PlaceholderBox>
          </Grid>
          <Grid item xs={10}>
            <PlaceholderLine thickness="thick" width="80%" color="extralight">
              {getName(id)}
            </PlaceholderLine>
            <PlaceholderLine thickness="thin" width="25%" />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default React.memo(EstimateListPlaceholderItem);
