import * as React from "react";
import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative"
  }
}));

const FormControl: React.FunctionComponent = (props) => {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
};

export default FormControl;
