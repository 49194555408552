import * as React from "react";
import { Grid, Switch } from "@material-ui/core";
import { EstimateRole } from "../../api/models";
import TrimmedTextField from "./TrimmedTextField";

const TaskTypeEditor: React.FunctionComponent<{
  role: EstimateRole;
  onNameChanged: (value: string) => any;
  onEnabledChanged: (enabled: boolean) => any;
}> = (props) => {
  const { role, onNameChanged, onEnabledChanged } = props;

  const nameChanged = (event: any) => {
    const value = event.target.value as string;
    onNameChanged(value);
  };

  const enabledChanged = () => {
    onEnabledChanged(!role.isEnabled);
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <TrimmedTextField disabled={!role.isEnabled} value={role.name} onChange={nameChanged} />
      </Grid>
      <Grid item xs={6}>
        <Switch checked={role.isEnabled} onChange={enabledChanged} />
      </Grid>
    </Grid>
  );
};

export default TaskTypeEditor;
