import * as React from "react";
import { Paper, Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2)
  }
}));

const ContentContainer: React.FunctionComponent<{ elevation?: number }> = (props) => {
  const classes = useStyles();
  const { elevation = 4 } = props;

  return (
    <Paper elevation={elevation} square className={`${classes.root}`}>
      {props.children}
    </Paper>
  );
};

export default ContentContainer;
