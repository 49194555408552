import { useQuery } from "@apollo/client";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { Client, EstimateClient, GraphQlConnection } from "../api/GraphQL/models";
import { GET_CLIENTS } from "../api/GraphQL/queries";

const ClientSelector: React.FunctionComponent<{ value: EstimateClient | null; onChange: (value: EstimateClient | null) => any }> = (
  props
) => {
  const [selectedValue, setSelectedValue] = React.useState<EstimateClient | null>(props.value);

  React.useEffect(() => {
    if (props.value?.clientId !== selectedValue?.clientId || props.value?.name !== selectedValue?.name) {
      setSelectedValue(props.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value?.clientId, props.value?.name]);

  const { data, loading } = useQuery<{ clients: GraphQlConnection<Client> }>(GET_CLIENTS, {
    variables: {
      first: 1000
    }
  });

  const clients = data?.clients.edges.map((e) => e.node) ?? [];

  const clientsMatch = (a: string, b: string): boolean => {
    return a.trim().toLocaleUpperCase() === b.trim().toLocaleUpperCase();
  };

  return (
    <>
      <Autocomplete
        freeSolo
        autoSelect
        value={selectedValue?.name || ""}
        loading={loading}
        options={clients.map((c) => c.name)}
        getOptionLabel={(value) => {
          return clients.find((c) => clientsMatch(c.name, value))?.name || value;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Who's it for?"
            placeholder={!props.value ? "Client name" : undefined}
            size="small"
            fullWidth
          />
        )}
        onChange={(event, newValue) => {
          if (!!newValue) {
            const match = clients.find((c) => clientsMatch(c.name, newValue));
            if (match) {
              props.onChange({ clientId: match.id, name: match.name });
            } else {
              props.onChange({ clientId: null, name: newValue });
            }
          } else {
            props.onChange(null);
          }
        }}
      />
    </>
  );
};

export default ClientSelector;
