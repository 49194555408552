import * as React from "react";
import { Grid, IconButton } from "@material-ui/core";
import HistoryIcon from "@material-ui/icons/History";
import Revision from "./Revision";
import { PageActionBar, PageSection } from "../../layout";
import { Estimate } from "../../api/models";

const RevisionHistory = (props: { estimate: Estimate }) => {
  const { estimate } = props;
  const [showHistory, setShowHistory] = React.useState(false);

  const onHistoryToggled = () => {
    setShowHistory(!showHistory);
  };

  return (
    <Grid container spacing={4}>
      {estimate.author && (
        <Grid item xs={12}>
          <PageSection title="Created by">
            <Revision author={estimate.author} date={estimate.dateCreated} />
          </PageSection>
        </Grid>
      )}
      <Grid item xs={12}>
        <PageActionBar>
          <IconButton onClick={onHistoryToggled}>
            <HistoryIcon />
          </IconButton>
        </PageActionBar>
        {showHistory ? (
          <PageSection title="Revisions">
            <Grid container spacing={2}>
              {estimate.revisions.map((revision, i) => {
                return (
                  <Grid item xs={12}>
                    <Revision key={i} author={revision.author} date={revision.date} comment={revision.comment} />
                  </Grid>
                );
              })}
            </Grid>
          </PageSection>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default RevisionHistory;
