import * as React from "react";
import { Grid, Button, Collapse, TextField, ButtonGroup } from "@material-ui/core";
import { ContentContainer } from "../../../layout";
import FilterListIcon from "@material-ui/icons/FilterList";
import CardViewIcon from "@material-ui/icons/GridOn";
import TableViewIcon from "@material-ui/icons/List";

export interface FilterProps {
  showingFilters: boolean;
  showingTable: boolean;

  onToggleFilters: () => any;
  onToggleTable: (showTable: boolean) => any;

  text: string;
  onTextFilterChanged: (event: React.ChangeEvent<HTMLInputElement>) => any;
}

const Filter = (props: FilterProps) => {
  const { showingFilters, showingTable, text } = props;

  return (
    <Grid container spacing={3} justifyContent="flex-end">
      <Grid item xs={12} className="filter-toggle-action">
        <Grid container>
          <Grid item xs={6}>
            <Button onClick={props.onToggleFilters} variant="contained">
              Filter&nbsp;
              <FilterListIcon />
            </Button>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <ButtonGroup variant="outlined" aria-label="outlined primary button group">
              <Button variant={!showingTable ? "contained" : "outlined"} onClick={() => props.onToggleTable(false)}>
                <CardViewIcon />
              </Button>
              <Button variant={showingTable ? "contained" : "outlined"} onClick={() => props.onToggleTable(true)}>
                <TableViewIcon />
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={showingFilters} className={`list-filters ${showingFilters ? "in" : ""}`}>
          <ContentContainer>
            <Grid container spacing={3} className="list-filters-inner">
              <Grid item xs={12} sm={6} md={8}>
                <TextField value={text} label="Search name, client" fullWidth onChange={props.onTextFilterChanged} />
              </Grid>
            </Grid>
          </ContentContainer>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default Filter;
