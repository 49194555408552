import * as React from "react";

import TagChip from "./TagChip";

const TagList = (props: { tagNames: string[] }) => {
  const { tagNames } = props;
  return (
    <div className="tag-list">
      {tagNames.map((roleName) => (
        <TagChip key={roleName} name={roleName} />
      ))}
    </div>
  );
};
export default TagList;
