import * as React from "react";
import { FormControl, Button } from "@material-ui/core";
import { useFormik } from "formik";
import { Estimate } from "../../api/models";
import { FormField, FormSection, Form, PageActionBar } from "../../layout";
import ClientSelector from "../../misc/ClientSelector";
import { EstimateClient } from "../../api/GraphQL/models";
import TrimmedTextField from "../editor/TrimmedTextField";
export interface EstimateCreateFormProps {
  estimate: Estimate;
  isFetching: boolean;
  onSaveRequested: (estimate: Estimate) => any;
}

const EstimateCreateForm = (props: EstimateCreateFormProps) => {
  const { estimate, isFetching, onSaveRequested } = props;
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  const formik = useFormik({
    initialValues: estimate,
    onSubmit: async (value) => {
      onSaveRequested(value);
    },
    validate: (value) => {
      const errors = {} as { [key in keyof Estimate]: string };

      const requiredFields: (keyof Estimate)[] = ["name"];
      for (const field of requiredFields) {
        if (!value[field]) {
          errors[field] = "Required";
        }
      }
      return errors;
    }
  });

  const clientChanged = (client: EstimateClient | null) => {
    formik.setFieldValue("client", client);
  };

  return (
    <form
      onSubmit={(e) => {
        setSubmitAttempted(true);
        formik.handleSubmit(e);
      }}>
      <Form>
        <FormSection fullWidth>
          <FormField>
            <FormControl fullWidth>
              <ClientSelector value={formik.values.client} onChange={clientChanged} />
            </FormControl>
          </FormField>
          <FormField>
            <FormControl fullWidth>
              <TrimmedTextField
                name="name"
                label="What are we estimating?"
                placeholder="ie. Ideation, Mobile app, Intranet rebuild"
                value={formik.values.name}
                error={submitAttempted && !!formik.errors.name}
                helperText={submitAttempted ? formik.errors.name : undefined}
                onChange={formik.handleChange}
                fullWidth
              />
            </FormControl>
          </FormField>
          <FormField>
            <PageActionBar>
              <Button color="primary" variant="contained" style={{ marginTop: "25px" }} disabled={isFetching} type="submit">
                {isFetching ? "Creating..." : "Create"}
              </Button>
            </PageActionBar>
          </FormField>
        </FormSection>
      </Form>
    </form>
  );
};

export default EstimateCreateForm;
