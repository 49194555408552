import * as React from "react";
import { Fade, Grid } from "@material-ui/core";
import debounce from "lodash.debounce";
import Filter from "./Filter";
import FilteredList from "./FilteredList";
import FilteredTable from "./FilteredTable";
import EstimateListPlaceholder from "./EstimateListPlaceholder";
import { EstimateSummary } from "../../../api/GraphQL/models";

const EstimateList = (props: { estimates: EstimateSummary[]; isFetching: boolean; onFilterChange: (search: string) => any }) => {
  const { estimates, isFetching, onFilterChange } = props;
  const [showFilters, setShowFilters] = React.useState(false);
  const [textFilter, setTextFilter] = React.useState("");
  const [toggleTable, setToggleTable] = React.useState(false);

  const onToggleFilter = () => {
    setShowFilters(!showFilters);
  };

  const onToggleTable = (showTable: boolean) => {
    setToggleTable(showTable);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateSearch = React.useCallback(
    debounce((nextValue: string) => {
      onFilterChange(nextValue);
    }, 500),
    [onFilterChange]
  );

  const onTextFilterChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = (e.target as any).value;
    setTextFilter(value);
    debouncedUpdateSearch(value);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Filter
          text={textFilter}
          showingFilters={showFilters}
          showingTable={toggleTable}
          onToggleFilters={onToggleFilter}
          onToggleTable={onToggleTable}
          onTextFilterChanged={onTextFilterChanged}
        />
      </Grid>
      <Grid item xs={12}>
        {toggleTable ? (
          <FilteredTable estimates={estimates} />
        ) : (
          <>
            <FilteredList estimates={estimates} />
            {isFetching && (
              <Fade in>
                <EstimateListPlaceholder />
              </Fade>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default EstimateList;
