import { Chip, makeStyles } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 500,
    "&.low": {
      background: theme.palette.success.main,
      color: theme.palette.success.contrastText
    },
    "&.medium": {
      background: theme.palette.warning.main,
      color: theme.palette.warning.contrastText
    },
    "&.high": {
      background: theme.palette.error.main,
      color: theme.palette.offCommon.black
    }
  }
}));

const RiskScoreChip: React.FunctionComponent<{ riskAssessmentScore: number }> = (props) => {
  const classes = useStyles();
  let scoreClass = "low";
  let scoreText = "Low";
  if (props.riskAssessmentScore > 1) {
    scoreClass = "medium";
    scoreText = "Medium";
  }

  if (props.riskAssessmentScore > 3) {
    scoreClass = "high";
    scoreText = "High";
  }

  return <Chip label={scoreText} className={`${classes.root} ${scoreClass}`} />;
};

export default RiskScoreChip;
