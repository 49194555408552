import * as React from "react";

export interface PlaceholderLineProps {
  color?: "light" | "dark" | "extralight";
  thickness?: "thin" | "thick";
  width?: string;
  className?: string;
  children?: React.ReactNode;
}

const PlaceholderLine = (props: PlaceholderLineProps) => {
  const { color = "light", thickness = "thin", className = "", width = "100%" } = props;

  return (
    <div className={`placeholder-line ${color} ${thickness} ${className}`} style={{ width }}>
      {props.children}
    </div>
  );
};

export default PlaceholderLine;
