import * as React from "react";
import { EstimatePlanTeamMember } from "../../../api/models";
import HeaderBadge from "./headerBadge";

const TeamSizeBadge: React.FunctionComponent<{ teamMembers: EstimatePlanTeamMember[] }> = (props) => {
  const { teamMembers } = props;
  return <HeaderBadge title="Team size" text={teamMembers.length.toString()} subtext={"people"} />;
};

export default TeamSizeBadge;
