import * as React from "react";
import { Typography } from "@material-ui/core";
import { HBarChartProps, HorizontalBarChart } from "../../charts/BarChart";
import { useWindowResizeListener } from "../../misc";
import { Estimate } from "../../api/models";

const EstimateChart: React.FunctionComponent<{ estimate: Estimate }> = (props) => {
  const chart = React.useRef<HorizontalBarChart | undefined>();
  const chartElement = React.createRef<HTMLDivElement>();

  const getBarChartState = React.useCallback((): HBarChartProps => {
    const data = [
      {
        id: "ABP",
        label: "ABP",
        value: Math.round(
          props.estimate.plan.areaTotals
            .map((area) => {
              return area.itemTotals.map((i) => i.adjustedRangeLow).reduce((a, b) => a + b, 0);
            })
            .reduce((a, b) => a + b, 0)
        ),
        className: "abp"
      },
      {
        id: "HP",
        label: "HP",
        value: Math.round(
          props.estimate.plan.areaTotals
            .map((area) => {
              return area.itemTotals.map((i) => i.adjustedRangeHigh).reduce((a, b) => a + b, 0);
            })
            .reduce((a, b) => a + b, 0)
        ),
        className: "hp"
      },
      {
        id: "E",
        label: "ABP + buffer",
        value: Math.round(
          props.estimate.plan.areaTotals
            .map((area) => {
              return area.itemTotals.map((i) => i.totalImplementerHours).reduce((a, b) => a + b, 0);
            })
            .reduce((a, b) => a + b, 0)
        ),
        className: "final"
      }
    ];

    return {
      el: chartElement.current,
      width: "100%",
      height: "auto",
      barHeight: 20,
      data,
      labelWidth: 200
    } as HBarChartProps;
  }, [chartElement, props.estimate.plan.areaTotals]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _ = useWindowResizeListener(() => chart.current?.update(getBarChartState()));

  React.useEffect(() => {
    if (chartElement) {
      chart.current = new HorizontalBarChart(getBarChartState());
    }
    return () => chart.current?.destroy();
  }, [chartElement, getBarChartState]);

  React.useEffect(() => {
    chart.current?.update(getBarChartState());
  }, [props.estimate, getBarChartState]);

  return (
    <div className="analytics-widget">
      <header>Buffered Estimate (Tasks only)</header>
      <div className="body">
        <div ref={chartElement} className="bar-chart-widget"></div>
      </div>

      <Typography component="p" className="comment">
        <span className="comment-abp-tag">ABP (aggressive but possible) + buffer</span> represents an approximation between the ABP number
        and the HP (Highly Probably) estimate that takes into account the distribution of ranges throughout the estimate and avoids both
        overly optimistic and overly pessimistic values
      </Typography>
    </div>
  );
};

export default EstimateChart;
