import * as React from "react";
import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { FinalPriceBadge, TotalHoursBadge } from "../detail/badges";
import { EstimatePublic } from "../../api/models";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    background: `linear-gradient(94deg, #001614 0%, ${theme.palette.primary.dark} 74.29%, ${theme.palette.primary.main} 81.39%, ${theme.palette.primary.light} 89.81%, ${theme.palette.secondary.main} 102.63%, ${theme.palette.secondary.dark} 102.64%)`,
    marginBottom: 0
  },
  content: {
    padding: theme.spacing(4)
  },
  client: {
    fontSize: theme.typography.h5.fontSize,
    color: "#fff",
    paddingTop: 0,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column"
  },
  clientName: {
    color: "inherit"
  },
  name: {
    paddingTop: 0,
    fontSize: theme.typography.h2.fontSize,
    lineHeight: theme.typography.h2.fontSize,
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  items: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between"
  }
}));

const EstimatePublicHeader: React.FunctionComponent<{ estimate: EstimatePublic; isSticky: boolean }> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.content}>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.client}>
              <Typography className={classes.clientName}>{props.estimate.client != null ? props.estimate.client.name : null}</Typography>
              <Typography className={classes.name}>{props.estimate.name}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.items}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FinalPriceBadge finalPrice={props.estimate.plan.finalPrice} discount={props.estimate.plan.discount} isPublicFacing />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TotalHoursBadge estimate={props.estimate} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default EstimatePublicHeader;
