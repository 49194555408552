import { Theme, Avatar, makeStyles } from "@material-ui/core";

type colorVariant = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12" | "13" | "14" | "15" | "16";
type sizeVariant = "sm" | "md" | "lg";

const useStyles = makeStyles((theme: Theme) => ({
  0: { backgroundColor: "#DDD" },
  1: { backgroundColor: "#F44336" },
  2: { backgroundColor: "#E53935" },
  3: { backgroundColor: "#E91E63" },
  4: { backgroundColor: "#D81B60" },
  5: { backgroundColor: "#9C27B0" },
  6: { backgroundColor: "#8E24AA" },
  7: { backgroundColor: "#673AB7" },
  8: { backgroundColor: "#5E35B1" },
  9: { backgroundColor: "#3F51B5" },
  10: { backgroundColor: "#3949AB" },
  11: { backgroundColor: "#2196F3" },
  12: { backgroundColor: "#1E88E5" },
  13: { backgroundColor: "#009688" },
  14: { backgroundColor: "#00897B" },
  15: { backgroundColor: "#43A047" },
  16: { backgroundColor: "#388E3C" },
  sm: { width: "28px", height: "28px" },
  md: { width: "36px", height: "36px" },
  lg: { width: "48px", height: "48px" }
}));

const ColoredAvatar = (props: { text: string; color?: colorVariant; size?: sizeVariant }) => {
  const classes = useStyles();
  return <Avatar className={`${classes[props.color || "0"]}`}>{props.text}</Avatar>;
};

export default ColoredAvatar;
