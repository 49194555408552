import * as React from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { useUserContext } from "../auth/UserContextProvider";
import { useAppSettings } from "../AppSettingsContextProvider";

const browserHistory = createBrowserHistory({ basename: "" });

interface TelemetryContextValue {
  ai: ApplicationInsights | undefined;
}

export const TelemetryContext = React.createContext<TelemetryContextValue>({ ai: undefined });
export const useTelemetry = () => React.useContext(TelemetryContext);

const TelemetryProvider: React.FunctionComponent = (props) => {
  const { settings } = useAppSettings();
  const { user } = useUserContext();

  const reactPlugin = React.useRef(new ReactPlugin()).current;
  const appInsights = React.useRef(
    new ApplicationInsights({
      config: {
        instrumentationKey: settings?.appInsightsKey,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
      }
    })
  ).current;

  React.useEffect(() => {
    appInsights.loadAppInsights();
  }, [appInsights]);

  React.useEffect(() => {
    if (!!user) {
      appInsights.setAuthenticatedUserContext(user?.id, user?.person.fullName, true);
    }
  }, [user, appInsights]);

  return (
    <TelemetryContext.Provider
      value={{
        ai: appInsights
      }}>
      {props.children}
    </TelemetryContext.Provider>
  );
};

export default TelemetryProvider;
