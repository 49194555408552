import HeaderBadge from "./headerBadge";

const RiskScoreBadge = (props: { score: number }) => {
  const { score } = props;
  let scoreClass: "success" | "warning" | "error" = "success";
  let scoreText = "Low";
  if (score > 3) {
    scoreClass = "error";
    scoreText = "High";
  } else if (score > 1) {
    scoreClass = "warning";
    scoreText = "Medium";
  }
  return <HeaderBadge title="Risk score" text={score.toString()} subtext={scoreText} color={scoreClass} noPadding />;
};

export default RiskScoreBadge;
