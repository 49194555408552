import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import * as RoutesModule from "./routes";
import AppSettingsContextProvider from "./AppSettingsContextProvider";
import "./styles/index.scss";

let routes = RoutesModule.routes;
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") || undefined;

const App: React.FunctionComponent = (props) => {
  return (
    <AppSettingsContextProvider>
      <BrowserRouter basename={baseUrl}>
        <div>
          <div className="estimates-app">
            <div className="main-content">{routes}</div>
          </div>
        </div>
      </BrowserRouter>
    </AppSettingsContextProvider>
  );
};
export default App;
