import * as React from "react";
import { Dialog, DialogTitle, DialogContent, Fade, TextField, DialogActions, Button } from "@material-ui/core";

export interface AddSharedKeyDialogProps {
  open: boolean;
  requestClose: () => any;
  addSharedKey: (recipientName: string, password: string) => any;
  generatedKey?: string;
  isFetching: boolean;
  appUrl: string;
}

export interface AddSharedKeyDialogState {
  recipientName: string;
  password: string;
  confirmPassword: string;
  passwordsMatch: boolean;
}

export class AddSharedKeyDialog extends React.Component<AddSharedKeyDialogProps, AddSharedKeyDialogState> {
  public state: AddSharedKeyDialogState = {
    recipientName: "",
    password: "",
    confirmPassword: "",
    passwordsMatch: true
  };

  public render() {
    const { open, requestClose, appUrl, generatedKey, isFetching } = this.props;
    const { recipientName, password, confirmPassword, passwordsMatch } = this.state;

    // For estimates that are not secured with a password, prepend "np-" as a way to hint to the view
    // that it can just fetch the estimate with a blank password instead of prompting
    const link = `${appUrl}/shared/estimate/${!password ? "np-" : ""}${generatedKey}`;
    return (
      <Dialog open={open} onClose={requestClose} PaperProps={{ style: { minWidth: "320px" } }}>
        <DialogTitle>Share this estimate</DialogTitle>
        <DialogContent>
          {generatedKey ? (
            <Fade in>
              <div>
                Give the following link to {recipientName} so they can view the estimate.
                <br />
                NOTE: anyone with the link can see the estimate, so please don't post it publicly.
                <br />
                <br />
                <a target="_blank" rel="noreferrer" className="share-link" href={link}>
                  {link}
                </a>
              </div>
            </Fade>
          ) : (
            <div>
              <p>Let's generate a unique URL that you can share.</p>
              <TextField fullWidth label="Recipient name" value={recipientName} onChange={this.onRecipientNameChange} />
              <TextField fullWidth label="Password (optional)" value={password} onChange={this.onPasswordChange} />
              <TextField fullWidth label="Confirm password" value={confirmPassword} onChange={this.onConfirmPasswordChange} />
              {!passwordsMatch ? <p>passwords don't match.</p> : null}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {!isFetching ? <Button onClick={requestClose}>{generatedKey ? "Close" : "Cancel"}</Button> : null}
          {!generatedKey && passwordsMatch ? (
            <Button disabled={isFetching} variant="contained" color="primary" onClick={this.addSharedKey}>
              {isFetching ? "Generating key..." : "Generate key"}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    );
  }

  private onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState((s) => ({ ...s, password: value, passwordsMatch: s.confirmPassword === value }));
  };

  private onConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState((s) => ({ ...s, confirmPassword: value, passwordsMatch: s.password === value }));
  };

  private onRecipientNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState((s) => ({ ...s, recipientName: value }));
  };

  private addSharedKey = () => {
    if (this.state.recipientName && this.state.recipientName.trim()) {
      this.setState((s) => ({ ...s, isFetching: true }));
      this.props.addSharedKey(this.state.recipientName, this.state.password);
    }
  };
}
