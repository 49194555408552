import * as React from "react";
import { ContentContainer, Form } from "../../layout";
import TaskTypesForm from "./TaskTypesForm";
import { ItemModifiersForm } from "./ItemModifiersForm";
import { SettableRoleProps, SettableEstimateItemModifierProps } from "../models";
import { EstimateItemModifier, EstimateRole } from "../../api/models";

interface EffortFormSettingsProps {
  roles: EstimateRole[];
  itemModifiers: EstimateItemModifier[];
  addItemModifier: () => any;
  deleteItemModifier: (modifierId: string) => any;
  setItemModifierProps: (modifierId: string, props: SettableEstimateItemModifierProps) => any;
  setRoleProps: (roleId: string, props: SettableRoleProps) => any;
}

class EffortFormSettings extends React.PureComponent<EffortFormSettingsProps> {
  public render() {
    const { roles, itemModifiers } = this.props;

    return (
      <ContentContainer elevation={0}>
        <Form fullWidth>
          <TaskTypesForm roles={roles} setRoleProps={this.props.setRoleProps} />
          <ItemModifiersForm
            modifiers={itemModifiers}
            roles={roles}
            addModifier={this.props.addItemModifier}
            deleteModifier={this.props.deleteItemModifier}
            setItemModifierProps={this.props.setItemModifierProps}
          />
        </Form>
      </ContentContainer>
    );
  }
}
export default EffortFormSettings;
