import { Estimate, EstimatePublic } from "../../../api/models";
import HeaderBadge from "./headerBadge";

const TotalHoursBadge = (props: { estimate: Estimate | EstimatePublic }) => {
  const { estimate } = props;
  const totalHours = Math.round(
    estimate.plan.areaTotals
      .map((a) => a.itemTotals)
      .reduce((a, b) => a.concat(b), [])
      .map((a) => a.totalImplementerHours + a.totalCoordinatorHours)
      .reduce((a, b) => a + b, 0)
  );

  return (
    <HeaderBadge
      title="Total hours"
      text={totalHours.toString()}
      subtext={
        <span>
          {estimate.areas.length} areas,&nbsp;
          {estimate.areas.map((a) => a.items.length).reduce((a, b) => a + b, 0)} items
        </span>
      }
    />
  );
};

export default TotalHoursBadge;
