import * as React from "react";
import { gql, useQuery } from "@apollo/client";
import { PersonDetail } from "../api/GraphQL/models";

export interface ApplicationUser {
  id: string;
  appRoles: string[];
  person: PersonDetail;
}

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      appRoles
      person {
        id
        emailAddress
        givenName
        surname
        fullName
        photos {
          uri
          size
        }
      }
    }
  }
`;

interface UserContextValue {
  user: ApplicationUser | null;
  isProfileAdmin: boolean;
}

export const UserContext = React.createContext<UserContextValue>({ user: null, isProfileAdmin: false });
export const useUserContext = () => React.useContext(UserContext);

const UserContextProvider = (props: { children: React.ReactNode }) => {
  const { data } = useQuery(GET_CURRENT_USER);

  const currentUser = data?.currentUser as ApplicationUser;
  return (
    <UserContext.Provider value={{ user: currentUser, isProfileAdmin: currentUser?.appRoles.indexOf("ProfileAdmin") >= 0 }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
