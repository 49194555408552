import * as React from "react";
import { MarkdownEditor } from "../../misc";
import { ContentContainer, Form, FormSection, FormField } from "../../layout";

interface NotesFormProps {
  notes: string;
  assumptions: string;

  setNotes: (notes: string) => any;
  setAssumptions: (assumptions: string) => any;
}

export class NotesForm extends React.Component<NotesFormProps> {
  public shouldComponentUpdate(nextProps: NotesFormProps) {
    return this.props.notes !== nextProps.notes || this.props.assumptions !== nextProps.assumptions;
  }

  public render() {
    const { notes, assumptions } = this.props;

    return (
      <ContentContainer elevation={0}>
        <Form fullWidth>
          <FormSection title="Notes" fullWidth>
            <FormField>
              <MarkdownEditor value={notes} onChange={this.props.setNotes} />
            </FormField>
          </FormSection>
          <FormSection title="Assumptions" fullWidth>
            <FormField>
              <MarkdownEditor value={assumptions} onChange={this.props.setAssumptions} />
            </FormField>
          </FormSection>
        </Form>
      </ContentContainer>
    );
  }
}
