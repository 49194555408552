import * as React from "react";
import { Grid, Fade } from "@material-ui/core";
import EstimateListPlaceholderItem from "./EstimateListPlaceholderItem";

const EstimateListPlaceholder = () => {
  const [itemCount, setItemCount] = React.useState(0);

  React.useEffect(() => {
    const timeout = window?.setInterval(() => {
      if (itemCount < 50) {
        setItemCount(itemCount + 1);
      } else {
        clearInterval(timeout);
      }
    }, 200);
    return () => clearInterval(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = Array.from(Array(itemCount).keys());
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <h3>Hang on!</h3>
        <Grid container spacing={2}>
          {items.map((i, index) => {
            return (
              <Fade in key={index}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <EstimateListPlaceholderItem id={index} />
                </Grid>
              </Fade>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EstimateListPlaceholder;
