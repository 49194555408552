import { Chip } from "@material-ui/core";
import React from "react";
import { EstimateStatus } from "../../api/GraphQL/models";
import { formatter } from "../../utils/formatter";

const EstimateStatusBadge: React.FunctionComponent<{ status: EstimateStatus }> = (props) => {
  const { status } = props;
  return <Chip label={formatter.formatEstimateStatus(status)} />;
};

export default EstimateStatusBadge;
