import * as React from "react";
import { ThemeProvider, useMediaQuery } from "@material-ui/core";
import { useAuth } from "./auth/AuthProvider";
import { GetLanternAppTheme, LoadingView } from "@devfacto/react-components/dist";
import AuthenticatedLayout from "./AuthenticatedLayout";

const AuthenticatedView: React.FunctionComponent = (props) => {
  const { isLoading, isAuthenticated, login, getAccessToken } = useAuth();
  const theme = useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light";
  React.useEffect(() => {
    if (!isLoading && !isAuthenticated && !!login) {
      window.sessionStorage.setItem("redirectUri", window.location.pathname);
      login();
    }
  }, [isLoading, isAuthenticated, login, getAccessToken]);

  return isAuthenticated ? (
    <AuthenticatedLayout>{props.children}</AuthenticatedLayout>
  ) : (
    <ThemeProvider theme={GetLanternAppTheme({ authenticated: false, type: theme })}>
      <LoadingView text="Authenticating..." />
    </ThemeProvider>
  );
};

export default AuthenticatedView;
