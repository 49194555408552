import * as React from "react";
import { Redirect } from "react-router-dom";
import { Tooltip, Fab } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

const ActionButton = (props: { estimateId: string }) => {
  const { estimateId } = props;
  const [redirectToEdit, setRedirectToEdit] = React.useState(false);

  const onEditClicked = () => {
    setRedirectToEdit(true);
  };

  if (redirectToEdit) {
    return <Redirect to={`/estimates/${estimateId}/edit`} push />;
  }

  return (
    <Tooltip title="Edit" className="speed-dial floating-action-button">
      <Fab variant="circular" color="primary" onClick={onEditClicked}>
        <EditIcon />
      </Fab>
    </Tooltip>
  );
};

export default ActionButton;
