import * as React from "react";
import { Switch, Grid, Theme } from "@material-ui/core";
import { PageActionBar, StretchedContent } from "../../layout";
import { TagChip } from "../../misc";
import { formatter } from "../../utils/formatter";
import { EstimatePublic, EstimateRole } from "../../api/models";

interface EstimateSummaryProps {
  estimate: EstimatePublic;
  theme: Theme;
}

interface EstimateSummaryState {
  showItems: boolean;
}

export class EstimatePublicSummary extends React.PureComponent<EstimateSummaryProps, EstimateSummaryState> {
  public state: EstimateSummaryState = { showItems: true };
  public shouldComponentUpdate(nextProps: EstimateSummaryProps, nextState: { showItems: boolean }) {
    return (
      nextProps.estimate !== this.props.estimate ||
      nextProps.estimate.plan !== this.props.estimate.plan ||
      nextState.showItems !== this.state.showItems
    );
  }

  public render() {
    if (!this.props.estimate || !this.props.estimate.plan) {
      return null;
    }

    const roleHash = {} as { [key: string]: EstimateRole };
    for (const role of this.props.estimate.roles) {
      roleHash[role.id] = role;
    }

    return (
      <div>
        <PageActionBar align="right">
          <span className="toggle-label">Detailed</span> <Switch checked={this.state.showItems} onChange={this.onToggleDetails} />
        </PageActionBar>
        <StretchedContent>
          <div className={`estimate-summary ${this.state.showItems ? "detailed" : "simple"}`}>
            {this.props.estimate.plan.areaTotals.map((areaTotal) => {
              const area = this.props.estimate.areas.find((a) => a.id === areaTotal.areaId);

              return (
                <Grid container spacing={1} className="area" key={areaTotal.areaId}>
                  <Grid item xs={8} md={10}>
                    <header>
                      {this.props.theme.palette.type === "light" ? (
                        <span className="area-name-light">{area?.name || "[unnamed area]"}</span>
                      ) : (
                        <span className="area-name-dark">{area?.name || "[unnamed area]"}</span>
                      )}
                      {!!area?.description ? <p className="area-description">{area?.description}</p> : null}
                    </header>
                  </Grid>
                  <Grid item xs={2} md={1} className="area-hours">
                    {Math.round(
                      areaTotal.itemTotals.map((t) => t.totalImplementerHours + t.totalCoordinatorHours).reduce((a, b) => a + b, 0) * 10
                    ) / 10}{" "}
                    hrs
                  </Grid>
                  <Grid item xs={2} md={1} className="area-cost">
                    ${formatter.formatCurrency(Math.round(areaTotal.totalCost))}
                  </Grid>
                  {this.state.showItems ? (
                    areaTotal.itemTotals.length ? (
                      areaTotal.itemTotals.map((itemTotal) => {
                        const item = area?.items.find((i) => i.id === itemTotal.itemId);
                        return !!item ? (
                          <Grid item xs={12} className="item" key={itemTotal.itemId}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={6} md={9}>
                                <header>
                                  <span className="item-name">{item.name || "[unnamed item]"}</span>
                                  {item.description ? <p className="item-description">{item.description}</p> : null}
                                </header>
                              </Grid>
                              <Grid item xs={8} sm={2} md={1}>
                                <div className="item-role">{item.roleId ? <TagChip name={roleHash[item.roleId].name} /> : null}</div>
                              </Grid>
                              <Grid item xs={2} sm={2} md={1}>
                                <div className="item-hours">
                                  {Math.round((itemTotal.totalImplementerHours + itemTotal.totalCoordinatorHours) * 10) / 10} hrs
                                </div>
                              </Grid>
                              <Grid item xs={2} sm={2} md={1}>
                                <div className="item-cost">${formatter.formatCurrency(Math.round(itemTotal.cost))}</div>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : null;
                      })
                    ) : (
                      <Grid item xs={12} className="item items-missing">
                        <header>This area has no items</header>
                      </Grid>
                    )
                  ) : null}
                </Grid>
              );
            })}
            <div className="totals">
              {this.props.estimate.plan.discount ? (
                <Grid container className="subtotal">
                  <Grid item xs={8}>
                    <header>
                      <span>Subtotal</span>
                    </header>
                  </Grid>
                  <Grid item xs={4} className="subtotal-cost">
                    ${formatter.formatCurrency(Math.round(this.props.estimate.plan.totalCost))}
                  </Grid>
                </Grid>
              ) : null}
              {this.props.estimate.plan.discount ? (
                <Grid container className="discount">
                  <Grid item xs={8}>
                    <header>
                      <span>Discount</span>
                    </header>
                  </Grid>
                  <Grid item xs={4} className="discount-cost">
                    <span className="negative-number">- ${formatter.formatCurrency(this.props.estimate.plan.discount)}</span>
                  </Grid>
                </Grid>
              ) : null}
              <Grid container className="total">
                <Grid item xs={8}>
                  <header>
                    <span>Total</span>
                  </header>
                </Grid>
                <Grid item xs={4} className="total-cost">
                  ${formatter.formatCurrency(Math.round(this.props.estimate.plan.finalPrice))}
                </Grid>
              </Grid>
            </div>
          </div>
        </StretchedContent>
      </div>
    );
  }

  private onToggleDetails = () => {
    this.setState({ showItems: !this.state.showItems });
  };
}
