import * as React from "react";
import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: `linear-gradient(94deg, #001614 0%, ${theme.palette.primary.dark} 74.29%, ${theme.palette.primary.main} 81.39%, ${theme.palette.primary.light} 89.81%, ${theme.palette.secondary.main} 102.63%, ${theme.palette.secondary.dark} 102.64%)`,
    borderTop: "2px solid rgba(0, 0, 0, 0.2)"
  },
  content: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "50px",
    position: "relative",
    boxSizing: "border-box"
  },
  header: {
    marginTop: 0,
    color: "#fff"
  }
}));

const SimpleHeader = (props: { title: string }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <h1 className={classes.header}>{props.title}</h1>
      </div>
    </div>
  );
};

export default SimpleHeader;
