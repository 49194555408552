import * as React from "react";
import PageSectionHeader from "./PageSectionHeader";
import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "@media print": {
      pageBreakInside: "avoid"
    }
  }
}));

const PageSection: React.FunctionComponent<{ title?: string }> = (props) => {
  const classes = useStyles();
  const { title = "" } = props;

  return (
    <div className={`${classes.root}`}>
      {props.title ? <PageSectionHeader title={title} /> : null}
      <div>{props.children}</div>
    </div>
  );
};

export default PageSection;
