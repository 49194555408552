import * as React from "react";
import { InputAdornment, Typography } from "@material-ui/core";
import { ContentContainer, Form, FormSection, FormField, FormControl } from "../../layout";
import { NumberInput } from "../../misc";

interface PlanModifiersFormProps {
  contingency: number;
  discount: number;
  setModifiers: (contingency: number, discount: number) => any;
}

export class PlanModifiersForm extends React.PureComponent<PlanModifiersFormProps> {
  public render() {
    return (
      <ContentContainer elevation={0}>
        <Form fullWidth>
          <FormSection title="Plan">
            <FormField>
              <Typography variant="caption">
                Use contingency and discount to adjust the total estimate price without affecting the estimated effort. Contingency is used
                to account for perceived risk and should be informed by the risk assessment.
              </Typography>
            </FormField>
            <FormField>
              <FormControl>
                <NumberInput
                  label="Contingency"
                  value={this.props.contingency}
                  onChange={this.onContingencyChanged}
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                />
              </FormControl>
            </FormField>
            <FormField>
              <NumberInput
                label="Discount"
                value={this.props.discount}
                onChange={this.onDiscountChanged}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
              />
            </FormField>
          </FormSection>
        </Form>
      </ContentContainer>
    );
  }

  private onContingencyChanged = (value: number | null) => {
    const newValue = value != null ? value : 0;
    this.props.setModifiers(newValue, this.props.discount);
  };

  private onDiscountChanged = (value: number | null) => {
    const newValue = value != null ? value : 0;
    this.props.setModifiers(this.props.contingency, newValue);
  };
}
