import * as React from "react";
import { AppHeader as LanternAppHeader } from "@devfacto/react-components";
import { useUserContext } from "./auth/UserContextProvider";

const AppHeader = () => {
  const { user } = useUserContext();

  return (
    <LanternAppHeader
      appName="Estimates"
      currentApp="estimates"
      user={
        user
          ? {
              id: user.id,
              photoUri: user.person.photos.length ? user.person.photos[0].uri : undefined
            }
          : undefined
      }
    />
  );
};

export default React.memo(AppHeader);
