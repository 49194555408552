import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";

import { unregister } from "./registerServiceWorker";

// const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") || undefined;

const rootElement = document.getElementById("root");

ReactDOM.render(<App />, rootElement);

unregister();
