import * as React from "react";
import { Grid, Typography } from "@material-ui/core";

const FormSection: React.FunctionComponent<{ title?: JSX.Element | string; fullWidth?: boolean }> = (props) => {
  const { title, fullWidth } = props;

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        {title ? (
          <Grid item xs={12}>
            <Typography variant="h5">{title}</Typography>
          </Grid>
        ) : null}
        <Grid item xs={12} sm={fullWidth ? 12 : 8} md={fullWidth ? 12 : 6} lg={fullWidth ? 12 : 4}>
          <Grid container spacing={3}>
            {props.children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormSection;
