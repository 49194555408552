import * as React from "react";
import { Grid, Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    marginBottom: "40px !important",
    "&.light": {
      background: "#fff"
    },
    "&.dark": {
      background: "#e9e8e9"
    }
  }
}));

const Form: React.FunctionComponent<{ fullWidth?: boolean }> = (props) => {
  const classes = useStyles();
  const { fullWidth = false } = props;
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={fullWidth ? 12 : 8} md={fullWidth ? 12 : 6} lg={fullWidth ? 12 : 4}>
          <Grid container spacing={8}>
            {props.children}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Form;
